import successDark from '../../assets/images/payment/iq-dm-payment-accepted.png';
import successLight from '../../assets/images/payment/iq-payment-accepted.png';
import { useFormContext } from 'react-hook-form';
import { ISimplePayConfiguration } from '../../core/interfaces/simplepay.interface';
import { ISurchargeResponse } from '../../core/interfaces';
import { formatTransactionCurrency } from '../../core/helpers/stringHelpers';
import { useTheme } from '@mui/material';

type SimplePaymentSuccessProps = {
  token?: string | null;
  transactionId: string;
  simpleConfiguration: ISimplePayConfiguration;
  surchargeResponse: ISurchargeResponse | null;
  isReceiptSent: boolean;
  prefersDarkTheme: boolean;
};
const SimplePaymentSuccess = ({
  transactionId,
  simpleConfiguration,
  surchargeResponse,
  isReceiptSent,
  prefersDarkTheme,
}: SimplePaymentSuccessProps) => {
  const methods = useFormContext();
  const theme = useTheme();
  const calculateTotal = (base: string, surcharge: number) => {
    return formatTransactionCurrency(parseFloat(base.replace(/[,\s]/g, '')) + surcharge);
  };
  const isDarkTheme = theme.palette.mode === 'dark';

  return (
    <div className='simple-success-wrapper'>
      <div>
        <div className='thanks-headline'>Thank you for your payment</div>
        {isReceiptSent && (
          <div>A receipt for this transaction was mailed to {methods.getValues('billing.email')} for your records</div>
        )}
        <div className='success-amount'>
          {surchargeResponse?.isSurchargeable
            ? calculateTotal(methods.getValues().paymentAmount, surchargeResponse.surchargeAmount)
            : methods.getValues('paymentAmount')}
        </div>
        <div className='image-wrapper'>{prefersDarkTheme ? <img src={successDark} /> : <img src={successLight} />}</div>
        <div className='transaction-id'>Transaction ID: {transactionId}</div>
        <button
          className='primary'
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            location.reload();
          }}
          style={{
            backgroundColor: isDarkTheme
              ? theme.palette.secondary.main
              : simpleConfiguration.brandingSettings.primaryColor || theme.palette.secondary.main,
          }}
        >
          Make Another Payment
        </button>
      </div>
    </div>
  );
};

export default SimplePaymentSuccess;
