import { Grid, Button } from '@mui/material';
import { useNavigate } from 'react-router';
import { invoiceRoutes } from '../../../core/constants/routes';

type ReturnPaymentButtonProps = {
  payableToken: string | undefined;
};
const ReturnPaymentButton = ({ payableToken }: ReturnPaymentButtonProps) => {
  const navigate = useNavigate();
  return (
    <Grid item className={'return-page-button'}>
      <Button
        variant='contained'
        onClick={() => navigate(invoiceRoutes.customerPayable.replace('/:payableToken', `/${payableToken}`))}
      >
        Return to Payment Page
      </Button>
    </Grid>
  );
};

export default ReturnPaymentButton;
