import { invoiceEndpoints } from '../../../core/constants/endpoints';
import { useState } from 'react';
import { monthNames } from '../../../core/constants/global';
import { useTheme } from '@mui/material';
import { formatTransactionCurrency } from '../../../core/helpers/stringHelpers';
import { IInvoiceResponse } from '../../../core/interfaces';
import { stringFormat } from '../../Global/Tokenizer/tokenizerHelpers';
import axios from 'axios';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import successLight from '../../../assets/images/payment/payment-success-light.png';
import successDark from '../../../assets/images/payment/payment-success-dark.png';

type InvoiceSuccessProps = {
  invoiceDetail: IInvoiceResponse;
  invoiceToken?: string | undefined;
  setErrorBanner: (arg0: string) => void;
};
const PaymentSuccess = ({ invoiceDetail, invoiceToken, setErrorBanner }: InvoiceSuccessProps) => {
  const [openBackdrop, setOpenBackdrop] = useState<boolean>(false);
  const todaysDate = new Date();
  const isDarkTheme = useTheme().palette.mode === 'dark';
  const downloadPdf = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setOpenBackdrop(true);
    const url = stringFormat(invoiceEndpoints.invoices.pdf, [invoiceToken!]);
    axios
      .get(url, {
        responseType: 'blob',
        headers: {
          Accept: 'application/pdf',
          Authorization: invoiceToken,
        },
      })
      .then((response) => {
        const blob = new Blob([response.data], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        window.open(url);
      })
      .catch(() => {
        setErrorBanner('Failed to download invoice pdf');
      })
      .finally(() => {
        setOpenBackdrop(false);
      });
  };
  return (
    <div className='invoice-success-wrapper'>
      <Backdrop sx={{ backgroundColor: 'rgba(0, 0, 0, 0.9)', zIndex: 9000 }} open={openBackdrop}>
        <div
          style={{
            color: 'white',
          }}
        >
          Loading PDF... <CircularProgress color='inherit' />
        </div>
      </Backdrop>
      <div>
        <h1>
          This invoice has been {invoiceDetail.partialAmount && invoiceDetail.remainingBalance > 0 && 'partially'} paid.
        </h1>
        <div className='payment-information'>
          {invoiceDetail.partialAmount && (
            <div>
              <span>AMOUNT PAID:</span> <span>{formatTransactionCurrency(invoiceDetail.partialAmount)}</span>
            </div>
          )}
          <div>
            <span>BALANCE REMAINING:</span>{' '}
            <span data-cy='amount-remaining'>{formatTransactionCurrency(invoiceDetail.remainingBalance)}</span>
          </div>
          <div>
            <span>DATE:</span>{' '}
            <span>{`${monthNames[todaysDate.getMonth()].substr(
              0,
              3
            )} ${todaysDate.getDate()}, ${todaysDate.getFullYear()}`}</span>
          </div>
        </div>
        <div className='image-wrapper'>
          <img src={isDarkTheme ? successDark : successLight} style={{ maxWidth: '300px' }}></img>
        </div>

        <p className='help-text'>Please contact {invoiceDetail.gateway.name} for further assistance.</p>
        <div className='invoice-information'>
          <div>INVOICE ID:</div> <div>{invoiceDetail.invoiceId}</div>
        </div>
        {invoiceDetail.invoiceId !== invoiceDetail.number && (
          <div style={{ marginTop: '-15px' }} className='invoice-information'>
            <div>INVOICE #:</div> <div>{invoiceDetail.number}</div>
          </div>
        )}

        <button className='primary' onClick={(e) => downloadPdf(e)}>
          View Invoice
        </button>
      </div>
    </div>
  );
};

export default PaymentSuccess;
