import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Tokenizer from 'fluidpay-tokenizer';
import colors from '../../../assets/sass/abstracts/colors.module.scss';
import { useTheme } from '@mui/material/styles';

const getSettings = (paymentType, mode) =>
  paymentType === 'card'
    ? {
        payment: {
          types: ['card'],
          showTitle: false,
        },
        styles: {
          body: {
            color: mode === 'light' ? colors.blue300 : colors.blue550,
          },
          '.card input': {
            height: '50px',
            'font-size': '16px',
          },
          '.card .cc .cc-icon': {
            display: 'none',
          },
          '.card .cc input': {
            'padding-left': '10px',
            'background-color': mode === 'dark' && colors.grey850,
            color: mode === 'dark' && colors.grey600,
          },
          '.card input.invalid': {
            color: '#ef5a00 !important',
            border: 'solid 1px #ef5a00 !important',
          },
          '.card .cc input:focus': {
            border: mode === 'light' ? `2px solid ${colors.blue550}` : `2px solid ${colors.blue300}`,
          },
          '.card .exp input': {
            'background-color': mode === 'dark' && colors.grey850,
            color: mode === 'dark' && colors.grey600,
            border: mode === 'dark' && '1px solid' + colors.grey500,
          },
          '.card .exp input:focus': {
            border: mode === 'light' ? `2px solid ${colors.blue550}` : `2px solid ${colors.blue300}`,
          },
          '.card .cvv input': {
            'background-color': mode === 'dark' && colors.grey850,
            color: mode === 'dark' && colors.grey600,
            border: mode === 'dark' && '1px solid' + colors.grey500,
          },
          '.card .cvv input:focus': {
            border: mode === 'light' ? `2px solid ${colors.blue550}` : `2px solid ${colors.blue300}`,
          },
          '.cc .fieldsetrow': {
            display: 'flex',
            'flex-wrap': 'wrap',
          },
          '.card .fieldsetrow .cc': {
            flex: '1 0 40%',
            'padding-right': '5px !important',
            order: '1',
          },
          '.card .fieldsetrow .exp': {
            flex: '1 0 40%',
            'padding-right': '5px !important',
            order: '2',
          },
          '.card .fieldsetrow .cvv': {
            flex: '1 0 40%',
            'padding-right': '5px !important',
            order: '3',
          },
        },
      }
    : {
        payment: {
          types: ['ach'],
          ach: {
            sec_code: 'ppd',
            secCodeList: ['web', 'ppd', 'ccd', 'tel'],
            showSecCode: false,
          },
          showTitle: false,
        },
        styles: {
          '.ach .fieldset': {
            'margin-top': '5px',
          },
          '.ach .fieldsetrow .selects+div .original': {
            'flex-flow': 'wrap',
          },
          '.ach input, select': {
            color: '#666',
            'font-size': '16px',
            height: '50px',
          },
          '.ach .fieldsetrow': {
            display: 'flex',
            'flex-wrap': 'wrap-reverse',
          },
          '.ach .fieldsetgroup': { width: '100%' },
          '.ach .fieldsetrow .account': {
            flex: '1 0 100%',
            order: '1',
          },

          '.ach .fieldsetrow .routing': {
            flex: '1 0 100%',
            order: '2',
          },
          '.ach .fieldsetrow .type': {
            flex: '1 0 100%',
            order: '3',
          },
          '.ach .fieldsetrow .sec-code': {
            flex: '1 0 40%',
            order: '4',
          },
          '.ach .account input': {
            'background-color': mode === 'dark' && colors.grey850,
            color: mode === 'dark' && colors.grey600,
            border: mode === 'dark' && '1px solid' + colors.grey500,
          },

          '.ach .account input:focus': {
            border: mode === 'light' ? `2px solid ${colors.blue550}` : `2px solid ${colors.blue300}`,
          },
          '.ach .routing input': {
            'background-color': mode === 'dark' && colors.grey850,
            color: mode === 'dark' && colors.grey600,
            border: mode === 'dark' && '1px solid' + colors.grey500,
          },
          '.ach input.invalid': {
            color: '#ef5a00 !important',
            border: 'solid 1px #ef5a00 !important',
          },
          '.ach .fieldset::before': {
            'line-height': '1em',
            padding: '0 0 7px 1px',
            display: 'inline-block',
            'font-size': '16px',
            color: mode === 'light' ? colors.grey900 : colors.grey600,
          },
          '.ach .routing input:focus': {
            border: mode === 'light' ? `2px solid ${colors.blue550}` : `2px solid ${colors.blue300}`,
          },
          '.ach .type select': {
            'background-color': mode === 'dark' && colors.grey850,
            color: mode === 'dark' && colors.grey600,
            padding: '5px 5px 5px 10px',
          },
          // ".ach .type::before": {
          //   content: '"Account Type"',
          //   "font-size": "14px",
          // },
          '.ach .type select:focus': {
            border: mode === 'light' ? `2px solid ${colors.blue550}` : `2px solid ${colors.blue300}`,
          },
          '.ach .sec-code select': {
            'background-color': mode === 'dark' && colors.grey850,

            padding: '5px 5px 5px 10px',
          },
          '.ach .sec-code select:focus': {
            border: mode === 'light' ? `2px solid ${colors.blue550}` : `2px solid ${colors.blue300}`,
          },
          // ".ach .sec-code::before": {
          //   content: '"SEC Code"',
          //   "font-size": "14px",
          // },
          // ".ach .account::before": {
          //   content: '"Account Number *"',
          //   "font-size": "14px",
          // },
          // ".ach .routing::before": {
          //   content: '"Routing Number *"',
          //   "font-size": "14px",
          // },
          '.ach .account input::placeholder,.ach .routing input::placeholder': {
            'font-size': '16px',
          },
        },
      };

export const useTokenizer = ({
  paymentType,
  container,
  apikey,
  onLoad,
  onSubmit,
  onAchChange,
  onMagStripeSwipe,
  onValidCard,
  tokenizerRef,
}) => {
  const [tokenizer, setTokenizer] = useState(null);
  // const [loading, setLoading] = useState(0)
  const theme = useTheme();

  const initializeTokenizer = () => {
    setTokenizer(
      new Tokenizer({
        apikey: apikey,
        url: process.env.REACT_APP_FP_BASE_URL,
        container: document.querySelector(container),
        onLoad: () => onLoad && onLoad(),
        submission: (resp) => onSubmit && onSubmit(resp),
        achOnChange: (data) => onAchChange && onAchChange(data),
        magStripeSwipe: (data) => onMagStripeSwipe && onMagStripeSwipe(data),
        validCard: (card) => onValidCard && onValidCard(card),
        settings: getSettings(paymentType, theme.palette.mode, true),
      })
    );
  };

  useEffect(() => {
    if (apikey && !tokenizer) {
      initializeTokenizer();
      // setLoading(false)
    }
  }, [apikey, container, onAchChange, onMagStripeSwipe, onLoad, onSubmit, paymentType, tokenizer]);

  useEffect(() => {
    if (tokenizer) {
      if (tokenizerRef && tokenizerRef.current) {
        while (tokenizerRef.current.firstChild) {
          tokenizerRef.current.removeChild(tokenizerRef.current.firstChild);
        }
      }

      initializeTokenizer();
    }
  }, [theme.palette.mode]);

  return tokenizer;
};

useTokenizer.propTypes = {
  paymentType: PropTypes.oneOf(['card', 'ach']).isRequired,
  container: PropTypes.string.isRequired,
  apikey: PropTypes.string.isRequired,
  onLoad: PropTypes.func,
  onSubmit: PropTypes.func,
  onAchChange: PropTypes.func,
  onMagStripeSwipe: PropTypes.func,
  onValidCard: PropTypes.func,
  tokenizerRef: PropTypes.object,
};

export default useTokenizer;
