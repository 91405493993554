import lightLogo from '../../assets/images/footer-basys.png';
import darkLogo from '../../assets/images/dark-mode/footer-basys.png';
import { useTheme } from '@mui/material';
import { LayoutContext } from '../../contexts/LayoutContext';
import { useContext } from 'react';
const Footer = () => {
  const theme = useTheme();
  const currentYear: number = new Date().getFullYear();
  const context = useContext(LayoutContext);
  return (
    <>
      {context?.layout?.footer ? (
        <div className='branding-footer'>
          <img src={context?.layout?.footer} />
        </div>
      ) : (
        <div className='footer'>
          <div className='footer-text'>
            <a href='https://iqpro.com/terms-of-service'>Terms & Conditions</a> <span>|</span>
            <a href='https://basyspro.com/privacy-policy/'>Privacy </a>
          </div>
          <div className='powered-by-block'>
            {' '}
            <span>Powered by</span>
            <img src={theme.palette.mode === 'light' ? lightLogo : darkLogo}></img>
            <span>&copy; {currentYear}</span>
          </div>
        </div>
      )}
    </>
  );
};

export default Footer;
