import { IInvoiceResponse } from '../../../core/interfaces';
import { formatDateTime, formatTransactionCurrency } from '../../../core/helpers/stringHelpers';
type Headerprops = {
  details: IInvoiceResponse;
};

const InvoicePaymentHeader = ({ details }: Headerprops) => {
  return (
    <div className='heading-container'>
      <div className='heading-container-left-col'>
        <h1>{details.title}</h1>
        <p>Invoice ID: {details.invoiceId}</p>
        {details.invoiceId !== details.number && <p>Invoice #: {details.number}</p>}
        <div className='balance-amt'>
          <span>Balance Due:</span>
          <span>{formatTransactionCurrency(details.remainingBalance)}</span>
        </div>
      </div>
      <div className='heading-container-right-col'>
        <div></div>
        <p>
          <span>Date Issued:</span> <span>{formatDateTime(details.invoiceDate).split(' ')[0]}</span>
        </p>
        <p className='text__blue'>
          {' '}
          <span>Date Due: </span> <span>{formatDateTime(details.dueDate).split(' ')[0]}</span>
        </p>
      </div>
    </div>
  );
};

export default InvoicePaymentHeader;
