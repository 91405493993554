import { Grid } from '@mui/material';
import { formatTransactionCurrency } from '../../../core/helpers/stringHelpers';
type TotalSelectedToPayProps = {
  container: string;
  amount: number;
};
const TotalSelectedToPay = ({ container, amount }: TotalSelectedToPayProps) => {
  return (
    <Grid
      className={container === 'invoice' ? 'total-selected invoice' : 'total-selected payment'}
      container
      direction={'row'}
    >
      <Grid item xs={8}>
        Total Selected to Pay
      </Grid>
      <Grid item xs={4} data-cy={'total-selected-to-pay'}>
        {formatTransactionCurrency(amount)}
      </Grid>
    </Grid>
  );
};
export default TotalSelectedToPay;
