import { useState, useRef, useEffect } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Card, ISurchargeResponse, ITokenizerConfig } from '../../core/interfaces';
import { useTokenizer } from '../Global/Tokenizer/FluidPayTokenizer';
import { ITokenInterface } from './SimplePay';
import CheckIcon from '@mui/icons-material/Check';
import TokenExComponent from '../Global/Tokenizer/TokenEx';
import IqProACHTokenizer from '../Global/Tokenizer/IqProACHTokenizer';
import { useFormContext } from 'react-hook-form';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Checkbox, FormControlLabel, styled, Tooltip, tooltipClasses, TooltipProps, useTheme } from '@mui/material';
import { ISimpleFieldSettings, ISimplePayConfiguration } from '../../core/interfaces/simplepay.interface';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

const StyledToolTip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
    fontSize: '16px',
  },
});

type SimplePaymentProps = {
  tokenConfiguration: ITokenizerConfig;
  simpleConfiguration: ISimplePayConfiguration;
  fieldSettings: ISimpleFieldSettings;
  handlePayment: (payload: ITokenInterface) => void;
  errorCount: number;
  errorMessage: string;
  simpleToken: string | undefined;
  paymentType: string;
  simplePayName: string;
  setBin: React.Dispatch<React.SetStateAction<string>>;
  activeTab: number;
  setActiveTab: React.Dispatch<React.SetStateAction<number>>;
  disabledButton: boolean;
  setDisabledButton: React.Dispatch<React.SetStateAction<boolean>>;
  surchargeResponse: ISurchargeResponse | null;
};

interface IFPTokenRes {
  status: string;
  token?: string;
}

const SimplePayPaymentInformation = ({
  tokenConfiguration,
  simpleConfiguration,
  fieldSettings,
  handlePayment,
  simpleToken,
  paymentType,
  errorCount,
  errorMessage,
  simplePayName,
  setBin,
  activeTab,
  setActiveTab,
  disabledButton,
  setDisabledButton,
  surchargeResponse,
}: SimplePaymentProps) => {
  const methods = useFormContext();
  const theme = useTheme();

  const [token, setToken] = useState<ITokenInterface | null>(null);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const cardRef = useRef<any>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const achRef = useRef<any>();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const tokenizerCardRef = useRef<any>();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const tokenizerAchRef = useRef<any>();

  const isDarkTheme = theme.palette.mode === 'dark';

  const handleOnValidCard = (card: Card) => {
    //use to find bin for masked number
    setBin(card!.bin!.bin);
    if (methods.getValues().bin) {
      methods.setValue('bin', card?.bin?.bin);
    } else {
      methods.register('bin');
      methods.setValue('bin', card?.bin?.bin);
    }
  };

  const handleFPCardSubmission = (res: IFPTokenRes) => {
    if (res.status === 'success') {
      setToken({
        token: res.token,
        paymentType: 'card',
        maskedNumber: methods.getValues().bin + '******1111',
      });
    } else {
      setDisabledButton(false);
    }
  };

  const handleFPACHSubmission = (res: IFPTokenRes) => {
    if (res.status === 'success') {
      setToken({
        token: res.token,
        paymentType: 'ach',
      });
    } else {
      setDisabledButton(false);
    }
  };

  const handleSubmission = async (event: React.SyntheticEvent) => {
    setDisabledButton(true);
    event.preventDefault();
    methods.trigger();

    if (paymentType === 'BOTH') {
      activeTab === 1 ? handleCardPayment() : handleACHPayment();
    } else {
      paymentType === 'CARD' ? handleCardPayment() : handleACHPayment();
    }
  };

  const handleCardPayment = () => {
    if (fpCardTokenizer) {
      fpCardTokenizer.submit();
    } else {
      cardRef.current!.validate();
    }
  };

  const handleACHPayment = () => {
    if (fpACHTokenizer) {
      fpACHTokenizer.submit();
    } else {
      achRef.current!.validate();
    }
  };

  const handleTokenExValid = (value: string | null) => {
    if (value) setBin(value);
  };

  const handleACHSubmit = (value: string) => {
    setToken({
      token: value,
      paymentType: 'ach',
    });
  };

  const handleACHError = () => {
    setDisabledButton(false);
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  const handleTokenExSubmit = (value: Card, exp: string | null) => {
    setToken({
      token: value.token,
      expirationDate: exp || '',
      maskedNumber: value.firstSix + '******' + value.lastFour,
      paymentType: 'card',
    });
  };

  const handleTokenExError = () => {
    setDisabledButton(false);
  };

  const formatPaymentMethods = (): string => {
    const paymentMethods = [
      { name: 'Visa', accepted: simpleConfiguration.cardSettings.acceptVisa },
      { name: 'Mastercard', accepted: simpleConfiguration.cardSettings.acceptMastercard },
      { name: 'Discover', accepted: simpleConfiguration.cardSettings.acceptDiscover },
      { name: 'Amex', accepted: simpleConfiguration.cardSettings.acceptAmex },
      { name: 'ACH', accepted: simpleConfiguration.achSettings.enableAch },
    ];
    return paymentMethods
      .filter((method) => method.accepted)
      .map((method) => method.name)
      .join(', ');
  };

  useEffect(() => {
    if (token) {
      handlePayment(token);
    }
  }, [token]);

  useEffect(() => {
    if (errorCount > 0) {
      setDisabledButton(false);
    }
  }, [errorCount]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const fpCardTokenizer: any | null =
    tokenConfiguration.cardTokenizer !== 'IQPROV2'
      ? useTokenizer({
          paymentType: 'card',
          apikey: tokenConfiguration.iframeConfiguration.iqPro?.publicKey,
          onSubmit: handleFPCardSubmission,
          onMagStripeSwipe: null,
          onAchChange: null,
          onLoad: null,
          onValidCard: handleOnValidCard,
          container: '#tokenizer-card',
          tokenizerRef: tokenizerCardRef,
        })
      : null;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const fpACHTokenizer: any | undefined =
    tokenConfiguration.achTokenizer !== 'IQPROV2'
      ? useTokenizer({
          paymentType: 'ach',
          apikey: tokenConfiguration.iframeConfiguration.iqPro?.publicKey,
          onSubmit: handleFPACHSubmission,
          onMagStripeSwipe: null,
          onAchChange: null,
          onLoad: null,
          onValidCard: null,
          container: '#tokenizer-ach',
          tokenizerRef: tokenizerAchRef,
        })
      : null;

  return (
    <div className='payment-info-container'>
      <div
        className={'payment-section'}
        style={{
          backgroundColor: isDarkTheme ? '#121212' : simpleConfiguration.brandingSettings.paymentColor || '#d4effa',
          backgroundImage: isDarkTheme
            ? 'linear-gradient(rgba(255, 255, 255, 0.16), rgba(255, 255, 255, 0.16))'
            : 'none',
        }}
      >
        <div className='payment-section-header'>
          <h2>Your {paymentType !== 'BOTH' && paymentType === 'CARD' ? 'Card' : 'Bank'} Payment Information</h2>
          <StyledToolTip title={formatPaymentMethods()} placement='right' arrow>
            <HelpOutlineIcon sx={{ marginLeft: '5px' }} />
          </StyledToolTip>
        </div>
        {paymentType === 'BOTH' && (
          <Tabs
            className='primary'
            value={activeTab}
            onChange={handleTabChange}
            aria-label='basic tabs example'
            sx={{
              '.MuiButtonBase-root.MuiTab-root.Mui-selected[type="button"][role="tab"]': {
                backgroundColor: isDarkTheme
                  ? theme.palette.secondary.main
                  : simpleConfiguration.brandingSettings.primaryColor || theme.palette.secondary.main,
              },
            }}
          >
            <Tab iconPosition='start' icon={<CheckIcon />} value={1} label='Card' />
            <Tab iconPosition='start' icon={<CheckIcon />} value={2} label='Bank Account' />
          </Tabs>
        )}
        <div hidden={activeTab !== 1}>
          <div ref={tokenizerCardRef} id='tokenizer-card'></div>
          {tokenConfiguration.cardTokenizer === 'IQPROV2' && tokenConfiguration?.iframeConfiguration?.iqProV2 && (
            <TokenExComponent
              tokenConfig={tokenConfiguration!.iframeConfiguration!.iqProV2}
              handleSubmit={handleTokenExSubmit}
              handleError={handleTokenExError}
              tokenizerRef={cardRef}
              validCard={handleTokenExValid}
              iqProToken={simpleToken}
            />
          )}
        </div>
        <div hidden={activeTab !== 2}>
          {tokenConfiguration.achTokenizer === 'IQPROV2' ? (
            <div>
              {activeTab === 2 && (
                <IqProACHTokenizer
                  handleError={handleACHError}
                  SECCode={2}
                  iqProToken={simpleToken}
                  tokenizerRef={achRef}
                  handleSubmit={handleACHSubmit}
                />
              )}
            </div>
          ) : (
            <div ref={tokenizerAchRef} id='tokenizer-ach'></div>
          )}
        </div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <button
            disabled={disabledButton}
            type='submit'
            onClick={handleSubmission}
            className='primary'
            style={{
              backgroundColor: isDarkTheme
                ? theme.palette.secondary.main
                : simpleConfiguration.brandingSettings.primaryColor || theme.palette.secondary.main,
            }}
          >
            Submit Payment
          </button>
        </div>
      </div>
      {errorMessage && (
        <div className='error-banner'>
          <ErrorOutlineIcon /> {errorMessage}
        </div>
      )}
      <p className='disclaimer'>
        By clicking "Submit Payment" you authorize {simplePayName} to charge your Payment Method
      </p>
      {surchargeResponse && activeTab === 1 && (
        <p className='disclaimer'>
          A surcharge rate of up to 3.000% will be applied to credit card transactions, which does not exceed our cost
          of acceptance. This surcharge is not applied to debit card or ACH transactions
        </p>
      )}
      {fieldSettings.includeEmailField && (
        <div className='email-receipt'>
          <FormControlLabel
            sx={{
              '.MuiButtonBase-root': {
                color: isDarkTheme
                  ? theme.palette.secondary.main
                  : simpleConfiguration.brandingSettings.primaryColor || theme.palette.secondary.main,
              },
              '.MuiButtonBase-root.Mui-checked': {
                color: isDarkTheme
                  ? theme.palette.secondary.main
                  : simpleConfiguration.brandingSettings.primaryColor || theme.palette.secondary.main,
              },
            }}
            control={<Checkbox {...methods.register('receipt')} />}
            label='Email a copy of this receipt'
          />
        </div>
      )}
    </div>
  );
};

export default SimplePayPaymentInformation;
