import { useFormContext } from 'react-hook-form';
import { MenuItem, TextField } from '@mui/material';
import { Controller } from 'react-hook-form';
import MUISelect from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useEffect, useState } from 'react';
import { IInvoiceAddress, IInvoiceResponse } from '../../../core/interfaces';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import { validationRules } from '../../../core/constants/validationRules';
import { Country, State } from 'country-state-city';
import { regexPatterns } from '../../../core/constants/regexPatterns';
import styles from '../../../assets/sass/abstracts/colors.module.scss';
type PaymentAddressProps = {
  type: string;
  defaultAddress?: IInvoiceAddress;
  handleStateChange?: (state: string) => void;
  emailReceipt?: boolean;
  disabledFields?: string[];
};
type PaymentAddressWrapperProps = {
  invoiceDetail: IInvoiceResponse | undefined;
  handleStateChange?: (state: string) => void;
  customerRequireShipping?: boolean;
  emailReceipt?: boolean;
  addressErrors: { billing: boolean; shipping: boolean };
  disabledFields?: string[];
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const validateField = (errors: any, type: any, property: string): boolean => {
  return Boolean(errors[type]?.[property]);
};
const PaymentAddress = ({
  type,
  defaultAddress,
  handleStateChange,
  emailReceipt,
  disabledFields,
}: PaymentAddressProps) => {
  const methods = useFormContext();
  const countries = Country.getAllCountries();
  const [states, setStates] = useState(State.getStatesOfCountry(defaultAddress?.country || 'US'));

  const onCountryChange = (country: string) => {
    setStates(State.getStatesOfCountry(country));
    methods.setValue(`${type}.state`, '');
    if (handleStateChange) {
      handleStateChange('');
    }
  };

  return (
    <div className='address-wrapper'>
      <div>
        <TextField
          {...methods.register(`${type}.firstName`, {
            required: { value: true, message: 'required' },
            maxLength: validationRules.maxLength50,
          })}
          disabled={disabledFields?.includes('firstName')}
          defaultValue={defaultAddress?.firstName || ''}
          error={validateField(methods.formState.errors, type, 'firstName')}
          className='text-input'
          id='firstName'
          placeholder='First Name'
        ></TextField>
        <TextField
          {...methods.register(`${type}.lastName`, {
            required: { value: true, message: 'required' },
            maxLength: validationRules.maxLength50,
          })}
          disabled={disabledFields?.includes('lastName')}
          defaultValue={defaultAddress?.lastName || ''}
          className='text-input'
          id='lastName'
          placeholder='Last Name'
          error={validateField(methods.formState.errors, type, 'lastName')}
        ></TextField>
        <TextField
          {...methods.register(`${type}.addressLine1`, {
            required: { value: true, message: 'required' },
          })}
          disabled={disabledFields?.includes('addressLine1')}
          className='text-input'
          defaultValue={defaultAddress?.addressLine1 || ''}
          id='addressLine1'
          error={validateField(methods.formState.errors, type, 'addressLine1')}
          placeholder='Address Line 1'
        ></TextField>
        <TextField
          {...methods.register(`${type}.addressLine2`, {
            required: { value: false, message: 'required' },
          })}
          disabled={disabledFields?.includes('addressLine2')}
          className='text-input'
          defaultValue={defaultAddress?.addressLine2 || ''}
          id='addressLine2'
          placeholder='Address Line 2'
          error={validateField(methods.formState.errors, type, 'addressLine2')}
        ></TextField>
        <TextField
          {...methods.register(`${type}.city`, {
            required: { value: true, message: 'required' },
          })}
          disabled={disabledFields?.includes('city')}
          className='text-input'
          id='city'
          defaultValue={defaultAddress?.city || ''}
          placeholder='City'
          error={validateField(methods.formState.errors, type, 'city')}
        ></TextField>
        <div className='state-zip-row'>
          <FormControl sx={{ width: '100%' }} {...methods.register(`${type}.state`, { required: true })}>
            <Controller
              name={`${type}.state`}
              defaultValue={defaultAddress?.state || ''}
              control={methods.control}
              render={({ field }) => (
                <MUISelect
                  disabled={disabledFields?.includes('state')}
                  onChange={(e) => {
                    field.onChange(e.target.value);
                    if (handleStateChange) {
                      handleStateChange(methods.getValues(`${type}.country`) === 'US' ? e?.target?.value || '' : '');
                    }
                  }}
                  displayEmpty={true}
                  renderValue={(selected) => {
                    return selected !== '' ? selected : 'State';
                  }}
                  className='text-input'
                  sx={{ color: field.value !== '' ? '#666' : styles.placeholder_text }}
                  id='state'
                  value={field.value}
                  error={validateField(methods.formState.errors, type, 'state')}
                >
                  {states.map((state, i) => {
                    return (
                      <MenuItem key={i} value={state.isoCode}>
                        {state.name}
                      </MenuItem>
                    );
                  })}
                </MUISelect>
              )}
            />
          </FormControl>
          <TextField
            {...methods.register(`${type}.postalCode`, {
              required: { value: true, message: 'required' },
              maxLength: 10,
              minLength: 5,
              pattern: regexPatterns.postalCode,
            })}
            disabled={disabledFields?.includes('postalCode')}
            className='text-input'
            defaultValue={defaultAddress?.postalCode || ''}
            id='postalCode'
            placeholder='Postal Code'
            error={validateField(methods.formState.errors, type, 'postalCode')}
          ></TextField>
        </div>
        <FormControl sx={{ width: '100%' }} {...methods.register(`${type}.country`, { required: true })}>
          <Controller
            name={`${type}.country`}
            defaultValue={defaultAddress?.country || 'US'}
            control={methods.control}
            render={({ field }) => (
              <MUISelect
                disabled={disabledFields?.includes('country')}
                onChange={(e) => {
                  field.onChange(e.target.value);
                  onCountryChange(e.target.value);
                }}
                displayEmpty={true}
                renderValue={(selected) => {
                  return selected !== '' ? selected : 'Country';
                }}
                className='text-input'
                sx={{ color: field.value !== '' ? '#666' : styles.placeholder_text }}
                id='country'
                value={field.value}
                error={validateField(methods.formState.errors, type, 'country')}
              >
                {countries.map((country, i) => {
                  return (
                    <MenuItem key={i} value={country.isoCode}>
                      {country.name}
                    </MenuItem>
                  );
                })}
              </MUISelect>
            )}
          />
        </FormControl>
        {emailReceipt !== undefined && (
          <TextField
            {...methods.register(`${type}.email`, {
              required: { value: emailReceipt, message: 'required' },
              pattern: validationRules.email,
            })}
            disabled={disabledFields?.includes('email')}
            className='text-input'
            id='email'
            placeholder='Email'
            error={validateField(methods.formState.errors, type, 'email')}
          ></TextField>
        )}
      </div>
    </div>
  );
};

const PaymentAddressWrapper = ({
  invoiceDetail,
  handleStateChange,
  customerRequireShipping,
  emailReceipt,
  addressErrors,
  disabledFields,
}: PaymentAddressWrapperProps) => {
  const [activeTab, setActiveTab] = useState(
    customerRequireShipping !== undefined ||
      (customerRequireShipping === undefined && invoiceDetail?.requireBillingInfo && invoiceDetail?.requireShippingInfo)
      ? 1
      : customerRequireShipping !== undefined ||
        (customerRequireShipping === undefined && invoiceDetail?.requireBillingInfo)
      ? 1
      : 2
  );
  const billingAddress: IInvoiceAddress | undefined = invoiceDetail?.invoiceAddresses.find((address) => {
    return address.isBilling;
  });

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  useEffect(() => {
    if (activeTab === 1) {
      if (!addressErrors.billing && addressErrors.shipping) {
        setActiveTab(2);
      }
    } else {
      if (!addressErrors.shipping && addressErrors.billing) {
        setActiveTab(1);
      }
    }
  }, [addressErrors]);
  return (
    <div className='address-container'>
      <h2>
        Your
        {customerRequireShipping ||
        (customerRequireShipping === undefined &&
          invoiceDetail?.requireBillingInfo &&
          invoiceDetail?.requireShippingInfo)
          ? ' Billing & Shipping Addresses are'
          : customerRequireShipping === false ||
            (customerRequireShipping === undefined && invoiceDetail?.requireBillingInfo)
          ? ' Billing Address is'
          : ' Shipping Address is'}{' '}
        Required
      </h2>
      {(customerRequireShipping ||
        (customerRequireShipping === undefined &&
          invoiceDetail?.requireBillingInfo &&
          invoiceDetail?.requireShippingInfo)) && (
        <Tabs className='secondary' value={activeTab} onChange={handleTabChange} aria-label='basic tabs example'>
          <Tab
            className={addressErrors.billing ? 'error' : ''}
            iconPosition='start'
            icon={<DescriptionOutlinedIcon />}
            value={1}
            label={'Billing'}
          />
          <Tab
            className={addressErrors.shipping ? 'error' : ''}
            iconPosition='start'
            icon={<LocalShippingIcon />}
            value={2}
            label='Shipping'
          />
        </Tabs>
      )}
      {(customerRequireShipping !== undefined ||
        (customerRequireShipping === undefined && invoiceDetail?.requireBillingInfo)) && (
        <div hidden={activeTab !== 1}>
          <PaymentAddress
            defaultAddress={customerRequireShipping === undefined ? billingAddress : undefined}
            type={'billing'}
            handleStateChange={handleStateChange}
            emailReceipt={emailReceipt}
            disabledFields={disabledFields}
          />
        </div>
      )}
      {(customerRequireShipping || (customerRequireShipping === undefined && invoiceDetail?.requireShippingInfo)) && (
        <div hidden={activeTab !== 2}>
          <PaymentAddress type={'shipping'} emailReceipt={emailReceipt} />
        </div>
      )}
    </div>
  );
};

export default PaymentAddressWrapper;
