import { regexPatterns } from './regexPatterns';

export const validationMessages = {
  alpha_only: 'Alpha only',
  numeric_only: 'Numeric only',
  alphanumeric_only: 'Alphanumeric only',
  city: 'Invalid city',
  invalid_email: 'Invalid email address',
  invalid_email_or_address: 'Invalid email address or addresses',
  name_field_req: 'Name is a required field',
  value_field_req: 'Value is a required field',
  vt_onsubmit_validation_error: 'Please correct validation errors highlighted in red.',
};

const getMaxLengthRule = (maxLength: number) => {
  return { value: maxLength, message: `Max length ${maxLength}` };
};

const getMinLengthRule = (minLength: number) => {
  return { value: minLength, message: `Min length ${minLength}` };
};

export const validationRules = {
  maxLength3: getMaxLengthRule(3),
  minLength9: getMinLengthRule(9),
  maxLength9: getMaxLengthRule(9),
  maxLength10: getMaxLengthRule(10),
  maxLength15: getMaxLengthRule(15),
  maxLength16: getMaxLengthRule(16),
  maxLength19: getMaxLengthRule(19),
  maxLength25: getMaxLengthRule(25),
  maxLength40: getMaxLengthRule(40),
  maxLength50: getMaxLengthRule(50),
  maxLength100: getMaxLengthRule(100),
  maxLength255: getMaxLengthRule(255),
  maxLength320: getMaxLengthRule(320),
  alphaSpace: {
    value: regexPatterns.alphaSpace,
    message: validationMessages.alpha_only,
  },
  numericSpace: {
    value: regexPatterns.numericSpace,
    message: validationMessages.numeric_only,
  },
  alphanumericSpace: {
    value: regexPatterns.alphanumericSpace,
    message: validationMessages.alphanumeric_only,
  },
  city: {
    value: regexPatterns.city,
    message: validationMessages.city,
  },
  email: {
    value: regexPatterns.email,
    message: validationMessages.invalid_email,
  },
  multipleEmail: {
    value: regexPatterns.multipleEmail,
    message: validationMessages.invalid_email_or_address,
  },
};
