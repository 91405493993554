interface InvoiceRoutes {
  readonly landing: string;
  readonly payment: string;
  readonly customerPayable: string;
  readonly customerPaymentSummary: string;
}

interface SimplePayRoutes {
  readonly simplePay: string;
}

export const invoiceRoutes: InvoiceRoutes = {
  landing: '/invoice',
  payment: '/invoice/:token',
  customerPayable: `/invoice/payable/:payableToken`,
  customerPaymentSummary: '/invoice/payablePaymentSummary/:payableToken',
};

export const simplePayRoutes: SimplePayRoutes = {
  simplePay: '/simple', //{token} through query param
};
export const generalError = '404';
