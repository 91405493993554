import { CssBaseline, useTheme } from '@mui/material';
import { useState } from 'react';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import { ILayout, LayoutContext } from '../../contexts/LayoutContext';
export interface LayoutProps {
  children: React.ReactNode;
}

const Layout = ({ children }: LayoutProps) => {
  const theme = useTheme();
  const [layout, setLayout] = useState<ILayout | null>(null);
  return (
    <LayoutContext.Provider value={{ layout, setLayout }}>
      <div
        className={
          theme.palette.mode +
          '-theme' +
          ((layout?.header || layout?.footer) && !layout?.showBackground ? ' no-background' : ' body-background')
        }
      >
        <CssBaseline />
        <Header />
        <main className='app-wrapper'>{children}</main>
        <footer>
          <Footer />
        </footer>
      </div>
    </LayoutContext.Provider>
  );
};

export default Layout;
