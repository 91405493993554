import cancelledError from '../../../assets/images/payment/payment-cancelled.png';
import allPaid from '../../../assets/images/payment/invoice-paid.png';
type InvoiceErrorProps = {
  errorCode: number;
  isPayable?: boolean;
};

export interface IERROR {
  [key: number]: {
    header: string;
    image: string;
    message: string;
  };
}

export const INVOICE_ERROR_CODES: IERROR = {
  401: {
    header: 'This Invoice Cannot Be Found.',
    image: cancelledError,
    message: 'Please contact support at (888) 366-1325 for further assistance.',
  },
  403: {
    header: 'This invoice has been cancelled.',
    image: cancelledError,
    message: 'This invoice has been cancelled. Please contact support for further assistance.',
  },
  404: {
    header: 'This Invoice Cannot Be Found',
    image: cancelledError,
    message: 'Please contact support at (888) 366-1325 for further assistance.',
  },
};

export const PAYABLE_ERROR_CODES: IERROR = {
  204: {
    header: 'You have no outstanding invoices.',
    image: allPaid,
    message:
      'No outstanding invoices can be found. If you believe this is a mistake, please contact support for further assistance.',
  },
  401: {
    header: 'Invoice(s) Cannot Be Found.',
    image: cancelledError,
    message: 'Please contact support at (888) 366-1325 for further assistance.',
  },
  405: {
    header: 'Invoice(s) Cannot Be Found.',
    image: cancelledError,
    message: 'Please contact support at (888) 366-1325 for further assistance.',
  },
};

const PaymentError = ({ errorCode, isPayable }: InvoiceErrorProps) => {
  const code = isPayable ? PAYABLE_ERROR_CODES[errorCode] : INVOICE_ERROR_CODES[errorCode];
  return (
    <div className='invoice-error-wrapper'>
      <h1> {code?.header}</h1>
      <div>
        <img src={code?.image} />
      </div>

      <p>{code.message}</p>
    </div>
  );
};

export default PaymentError;
