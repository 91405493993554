import { useState, useMemo, useEffect } from 'react';
import { BrowserRouter as Router, Navigate } from 'react-router-dom';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { ErrorBoundary } from 'react-error-boundary';
import { PaletteMode, useMediaQuery } from '@mui/material';
import { ColorModeContext } from './contexts/ColorModeContext';
import Layout from './components/Layout/Layout';
import InvoiceRoutes from './components/Routes/InvoiceRoutes';
import getTheme from './assets/theme';

const App = () => {
  // No idea if we're ever gonna implement dark mode, but here it is just in case
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const [mode, setMode] = useState(prefersDarkMode ? 'dark' : 'light');
  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
      },
    }),
    []
  );
  const theme = useMemo(() => getTheme(mode as PaletteMode), [mode]);

  useEffect(() => {
    setMode(prefersDarkMode ? 'dark' : 'light');
  }, [prefersDarkMode]);

  return (
    <StyledEngineProvider injectFirst>
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <Router>
            <ErrorBoundary fallbackRender={() => <Navigate to='/error' />}>
              <Layout>
                <InvoiceRoutes />
              </Layout>
            </ErrorBoundary>
          </Router>
        </ThemeProvider>
      </ColorModeContext.Provider>
    </StyledEngineProvider>
  );
};

export default App;
