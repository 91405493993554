import { useEffect, useState } from 'react';

const useScript = (resourceUrl, forceReload) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = resourceUrl;
    script.async = true;
    script.onload = () => {
      setIsLoading(false);
    };
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, [resourceUrl, forceReload]);

  return [isLoading];
};
export default useScript;
