import { useState, useRef, useEffect } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Card, IHTTPInterface, IInvoiceResponse, ISurchargeResponse, ITokenizerConfig } from '../../../core/interfaces';
import { useTokenizer } from '../../Global/Tokenizer/FluidPayTokenizer';
import { TextField } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import TokenExComponent from '../../Global/Tokenizer/TokenEx';
import ACHTokenizerComponent from '../../Global/Tokenizer/IqProACHTokenizer';
import PaymentAddressWrapper from './PaymentAddresses';
import { ITokenInterface } from './Payment';
import CheckIcon from '@mui/icons-material/Check';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { invoiceEndpoints } from '../../../core/constants/endpoints';
import axios, { AxiosResponse } from 'axios';
import { convertToInt, formatNumber, formatTransactionCurrency } from '../../../core/helpers/stringHelpers';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import { useDebounce } from '../../../hooks/useDebounce';
import { stringFormat } from '../../Global/Tokenizer/tokenizerHelpers';
type InvoicePaymentProps = {
  tokenConfiguration: ITokenizerConfig;
  invoiceDetail: IInvoiceResponse;
  handlePayment: (payload: ITokenInterface, isFull: boolean) => void;
  errorCount: number;
  invoiceToken: string | undefined;
  errorMessage: string;
};

interface IFPTokenRes {
  status: string;
  token?: string;
}

interface ICalculateFees {
  creditCardBin: string;
  state: string;
  baseAmount: number;
  addTaxToTotal: boolean;
  taxAmount?: number;
  processorId: string;
}

const InvoicePaymentInformation = ({
  tokenConfiguration,
  invoiceDetail,
  handlePayment,
  invoiceToken,
  errorMessage,
  errorCount,
}: InvoicePaymentProps) => {
  const paymentType =
    invoiceDetail.invoicePaymentMethod.card && invoiceDetail.invoicePaymentMethod.ach
      ? 'BOTH'
      : invoiceDetail.invoicePaymentMethod.card
      ? 'CARD'
      : 'ACH';
  const methods = useFormContext();
  const {
    formState: { errors },
  } = useFormContext();
  const [activeTab, setActiveTab] = useState(paymentType === 'BOTH' || paymentType === 'CARD' ? 1 : 2);
  const [bin, setBin] = useState<string>('');
  const [surchargeState, setSurchargeState] = useState<string>(
    invoiceDetail.invoiceAddresses.find((address) => {
      return address.isBilling;
    })?.state || ''
  );
  const [surchargeResponse, setSurchargeResponse] = useState<ISurchargeResponse | null>(null);
  const [isPaidInFull, setPaidInFull] = useState<boolean>(true);
  const [partialAmount, setPartialAmount] = useState<string>('0.00');
  const debouncedPartial = useDebounce<string>(partialAmount, 1000);
  const [token, setToken] = useState<ITokenInterface | null>(null);
  const [disableButton, setDisabledButton] = useState<boolean>(invoiceDetail.surcharge && paymentType !== 'ACH');
  const [addressErrors, setAddressErrors] = useState<{ billing: boolean; shipping: boolean }>({
    billing: false,
    shipping: false,
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const inputRef = useRef<any>();

  const moveInputPositionToEnd = () => {
    inputRef.current!.setSelectionRange(Number.MAX_SAFE_INTEGER, Number.MAX_SAFE_INTEGER);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const cardRef = useRef<any>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const achRef = useRef<any>();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const tokenizerCardRef = useRef<any>();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const tokenizerAchRef = useRef<any>();

  const handleCurrencyPipe = (value: string) => {
    // clean up commas to be valid number
    const cleanVal: number = parseFloat(value.replace(/[.,\s]/g, ''));
    const formattedNumber: string = formatNumber(convertToInt(+cleanVal), 2, '0,0.00');
    setPartialAmount(formattedNumber);
  };

  const handleOnValidCard = (card: Card) => {
    //use to find bin for masked number
    setBin(card!.bin!.bin);
    if (methods.getValues().bin) {
      methods.setValue('bin', card?.bin?.bin);
    } else {
      methods.register('bin');
      methods.setValue('bin', card?.bin?.bin);
    }
  };

  const handleFPCardSubmission = (res: IFPTokenRes) => {
    if (res.status === 'success') {
      setToken({
        token: res.token,
        paymentType: 'card',
        maskedNumber: methods.getValues().bin + '******1111',
      });
    } else {
      setDisabledButton(false);
    }
  };
  const handleFPACHSubmission = (res: IFPTokenRes) => {
    if (res.status === 'success') {
      setToken({
        token: res.token,
        paymentType: 'ach',
      });
    } else {
      setDisabledButton(false);
    }
  };
  const handleTokenExSubmit = (value: Card, exp: string | null) => {
    setToken({
      token: value.token,
      expirationDate: exp || '',
      maskedNumber: value.firstSix + '******' + value.lastFour,
      paymentType: 'card',
    });
  };

  const handleTokenExError = () => {
    setDisabledButton(false);
  };

  const handleTokenExValid = (value: string | null) => {
    if (value) setBin(value);
  };

  const handleACHSubmit = (value: string) => {
    setToken({
      token: value,
      paymentType: 'ach',
    });
  };
  const handleACHError = () => {
    setDisabledButton(false);
  };
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  const handleSubmission = async (event: React.SyntheticEvent) => {
    setDisabledButton(true);
    event.preventDefault();
    methods.trigger().then(() => {
      setAddressErrors({
        billing: Object.hasOwn(errors, 'billing'),
        shipping: Object.hasOwn(errors, 'shipping'),
      });
    });

    if (paymentType === 'BOTH') {
      activeTab === 1 ? handleCardPayment() : handleACHPayment();
    } else {
      paymentType === 'CARD' ? handleCardPayment() : handleACHPayment();
    }
  };

  const handleCardPayment = () => {
    if (fpCardTokenizer) {
      fpCardTokenizer.submit();
    } else {
      cardRef.current!.validate();
    }
  };

  const handleACHPayment = () => {
    if (fpACHTokenizer) {
      fpACHTokenizer.submit();
    } else {
      achRef.current!.validate();
    }
  };

  const handleStateChange = (state: string) => {
    setSurchargeState(state);
  };

  const calculateFees = () => {
    const url: string = stringFormat(invoiceEndpoints.calculateFees, [invoiceToken!]);
    const payload: ICalculateFees = {
      creditCardBin: bin,
      state: surchargeState,
      baseAmount: isPaidInFull ? invoiceDetail.remainingBalance : +partialAmount,
      addTaxToTotal: false,
      taxAmount: invoiceDetail.tax,
      processorId: invoiceDetail.invoicePaymentMethod.cardProcessorId,
    };
    axios
      .post(url, payload, { headers: { Authorization: invoiceToken } })
      .then((res: AxiosResponse<IHTTPInterface>) => {
        setSurchargeResponse(res.data.data.isSurchargeable ? res.data.data : null);
      })
      .catch(() => {
        setSurchargeResponse(null);
      })
      .finally(() => {
        setDisabledButton(false);
      });
  };

  useEffect(() => {
    if (bin && surchargeState && invoiceDetail.surcharge) {
      calculateFees();
    }
  }, [bin, surchargeState, debouncedPartial, isPaidInFull]);

  useEffect(() => {
    if (invoiceDetail.surcharge && activeTab === 1) {
      setDisabledButton(true);
    }
    if (activeTab === 2) {
      setDisabledButton(false);
    }
  }, [partialAmount, isPaidInFull, activeTab]);

  useEffect(() => {
    if (token) {
      handlePayment(token, isPaidInFull);
    }
  }, [token]);

  useEffect(() => {
    if (errorCount > 0) {
      setDisabledButton(true);
    } else {
      setDisabledButton(false);
    }
  }, [errorCount]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const fpCardTokenizer: any | null =
    tokenConfiguration.cardTokenizer !== 'IQPROV2'
      ? useTokenizer({
          paymentType: 'card',
          apikey: tokenConfiguration.iframeConfiguration.iqPro?.publicKey,
          onSubmit: handleFPCardSubmission,
          onMagStripeSwipe: null,
          onAchChange: null,
          onLoad: null,
          onValidCard: handleOnValidCard,
          container: '#tokenizer-card',
          tokenizerRef: tokenizerCardRef,
        })
      : null;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const fpACHTokenizer: any | undefined =
    tokenConfiguration.achTokenizer !== 'IQPROV2'
      ? useTokenizer({
          paymentType: 'ach',
          apikey: tokenConfiguration.iframeConfiguration.iqPro?.publicKey,
          onSubmit: handleFPACHSubmission,
          onMagStripeSwipe: null,
          onAchChange: null,
          onLoad: null,
          onValidCard: null,
          container: '#tokenizer-ach',
          tokenizerRef: tokenizerAchRef,
        })
      : null;

  return (
    <div className='payment-info-container invoice'>
      <div className='payment-section'>
        <h2>Your {paymentType !== 'BOTH' && paymentType === 'CARD' ? 'Card' : 'Bank'} Payment Information</h2>
        {paymentType === 'BOTH' && (
          <Tabs className='primary' value={activeTab} onChange={handleTabChange} aria-label='basic tabs example'>
            <Tab iconPosition='start' icon={<CheckIcon />} value={1} label='Card' />
            <Tab iconPosition='start' icon={<CheckIcon />} value={2} label='Bank Account' />
          </Tabs>
        )}

        <div hidden={activeTab !== 1}>
          <div ref={tokenizerCardRef} id='tokenizer-card'></div>
          {tokenConfiguration.cardTokenizer === 'IQPROV2' && tokenConfiguration?.iframeConfiguration?.iqProV2 && (
            <TokenExComponent
              tokenConfig={tokenConfiguration!.iframeConfiguration!.iqProV2}
              handleSubmit={handleTokenExSubmit}
              handleError={handleTokenExError}
              tokenizerRef={cardRef}
              validCard={handleTokenExValid}
              iqProToken={invoiceToken}
            />
          )}
        </div>
        <div hidden={activeTab !== 2}>
          {tokenConfiguration.achTokenizer === 'IQPROV2' ? (
            <div>
              {activeTab === 2 && (
                <ACHTokenizerComponent
                  handleError={handleACHError}
                  SECCode={invoiceDetail.invoicePaymentMethod.secCode}
                  iqProToken={invoiceToken}
                  tokenizerRef={achRef}
                  handleSubmit={handleACHSubmit}
                />
              )}
            </div>
          ) : (
            <div ref={tokenizerAchRef} id='tokenizer-ach'></div>
          )}
        </div>
        {(invoiceDetail.requireBillingInfo || invoiceDetail.requireShippingInfo || invoiceDetail.surcharge) && (
          <PaymentAddressWrapper
            handleStateChange={handleStateChange}
            invoiceDetail={invoiceDetail}
            addressErrors={addressErrors}
          />
        )}
        {(!Object.hasOwn(invoiceDetail, 'allowPartialPayments') || invoiceDetail?.allowPartialPayments) && (
          <div>
            <FormControl>
              <RadioGroup
                row
                defaultValue={'true'}
                name={'paymentAmount'}
                onChange={(e) => {
                  setPaidInFull(e.target.value === 'true');
                }}
                sx={{ paddingLeft: '10px' }}
              >
                <FormControlLabel labelPlacement='end' value={'true'} control={<Radio />} label='Pay In Full' />
                <FormControlLabel labelPlacement='end' value={'false'} control={<Radio />} label='Pay Other Amount' />
              </RadioGroup>
            </FormControl>
          </div>
        )}

        {!isPaidInFull && (
          <TextField
            {...methods.register('paymentAmount', {
              required: { value: true, message: 'required' },
            })}
            error={methods.formState.errors['paymentAmount']?.message ? true : false}
            className='text-input'
            value={partialAmount}
            onChange={(e) => handleCurrencyPipe(e.target.value)}
            onClick={moveInputPositionToEnd}
            onKeyDown={moveInputPositionToEnd}
            onKeyUp={moveInputPositionToEnd}
            id='paymentAmount'
            inputRef={inputRef}
            InputProps={{
              startAdornment: (
                <InputAdornment sx={{ marginRight: '-5px' }} position='start'>
                  $
                </InputAdornment>
              ),
            }}
          ></TextField>
        )}
        {surchargeResponse && activeTab === 1 && (
          <div className='surcharge-totals'>
            <div>
              <span>Surcharge:</span>
              <span data-cy='surcharge-amount'>{formatTransactionCurrency(surchargeResponse.surchargeAmount)}</span>
            </div>
            <div>
              <span>Total:</span>
              <span data-cy='transaction-total'>{formatTransactionCurrency(surchargeResponse.amount)}</span>
            </div>
          </div>
        )}

        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <button disabled={disableButton} type='submit' onClick={handleSubmission} className='primary'>
            Submit Payment
          </button>
        </div>
        {surchargeResponse && activeTab === 1 && (
          <p className='surcharge-text'>
            A surcharge rate of up to 3.000% will be applied to credit card transactions, which does not exceed our cost
            of acceptance. This surcharge is not applied to debit card or ACH transactions
          </p>
        )}
      </div>
      {errorMessage && (
        <div className='error-banner'>
          <ErrorOutlineIcon /> {errorMessage}
        </div>
      )}
      <p className='disclaimer'>
        By clicking "Submit Payment" you authorize {invoiceDetail.gateway.name} to charge your Payment Method
      </p>
    </div>
  );
};

export default InvoicePaymentInformation;
