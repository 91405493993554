import React, { Dispatch, SetStateAction } from 'react';

export interface ILayout {
  header: string;
  footer: string;
  isHeaderCentered?: boolean;
  showBackground?: boolean;
}
export type Layout = {
  layout: ILayout | null;
  setLayout: Dispatch<SetStateAction<ILayout | null>>;
};
export const LayoutContext = React.createContext<Layout | null>(null);
