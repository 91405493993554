/* eslint-disable prefer-const */
import {
  Dialog,
  DialogActions,
  Grid,
  Button,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Tooltip,
} from '@mui/material';
import { Dispatch, SetStateAction, useContext, useEffect } from 'react';
import { CustomerPayableInterface } from '../CustomerPayables/CustomerPayables';
import InvoiceHeaderRow from './InvoiceHeaderRow';
import IconButton from '@mui/material/IconButton';
import CancelIcon from '@mui/icons-material/Cancel';
import InvoiceListTotalsRow from './InvoiceListTotalsRow';
import { useState } from 'react';
import { ICalculateFeesProps } from './CustomerPayables';
import axios from 'axios';
import { invoiceEndpoints } from '../../../core/constants/endpoints';
import ErrorIcon from '@mui/icons-material/Error';
import AlertModal from '../../Global/Tokenizer/AlertModal';
import { useNavigate } from 'react-router';
import InvoiceTableCells from './InvoiceTableCells';
import { CustomerPayablesContext, CustomerPayablesContextType } from '../../../contexts/CustomerPayablesContext';
import { useTheme } from '@mui/material';
import { stringFormat } from '../../Global/Tokenizer/tokenizerHelpers';

type AlertModalProps = {
  tabOpen: boolean;
  onConfirm: () => void;
  setModalAmounts: Dispatch<SetStateAction<{ [key: string]: string }>>;
  setModalRows: Dispatch<SetStateAction<CustomerPayableInterface[]>>;
  setModalSurcharges: Dispatch<SetStateAction<{ [key: string]: number }>>;
};

const InvoicesSubmitModal = ({
  tabOpen,
  onConfirm,
  setModalAmounts,
  setModalRows,
  setModalSurcharges,
}: AlertModalProps) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const {
    selectedRows,
    surcharge,
    paymentTab,
    savedSurcharges,
    amountToPay,
    originalResponse,
    binFilled,
    modalAmounts,
    modalRows,
    modalSurcharges,
    setOpenSubmitModal,
    setDisableSubmit,
    setSubmitClicked,
    surchargeState,
  } = useContext(CustomerPayablesContext) as CustomerPayablesContextType;
  const [modalSurchargeLoading, setModalSurchargeLoading] = useState(false);
  const [modalErrorList, setModalErrorList] = useState<string[]>([]);
  const [disableModalSubmit, setDisableModalSubmit] = useState(false);
  const [modalSurchargeError, setModalSurchargeError] = useState(false);
  const [showSurchargeModal, setShowSurchargeModal] = useState(false);
  useEffect(() => {
    if (selectedRows.length > 0) {
      const newRows = selectedRows.map((r) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { invoiceDate, ...newRow } = r;
        return newRow;
      });
      setModalRows(newRows);
    }
    if (Object.keys(amountToPay).length > 0) {
      setModalAmounts(amountToPay);
    }
    if (Object.keys(savedSurcharges).length > 0) {
      setModalSurcharges(savedSurcharges);
    }
  }, [selectedRows, amountToPay, savedSurcharges, tabOpen]);

  const totalAmountToPay = Object.keys(modalAmounts).reduce((sum, key) => {
    return sum + parseFloat(modalAmounts[key].replace(/,/g, ''));
  }, 0);

  const totalSurcharge = Object.keys(modalSurcharges).reduce((a, r) => {
    return a + modalSurcharges[r];
  }, 0);

  const calculateFees = async (payloadArray: ICalculateFeesProps[]) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let axiosCalls: any = payloadArray.map((obj, i) =>
      axios
        .post(stringFormat(invoiceEndpoints.calculateFees, [obj.invoiceToken]), payloadArray[i], {
          headers: {
            Authorization: obj.invoiceToken,
          },
        })
        .catch(() => {
          return null;
        })
    );
    return axios
      .all(axiosCalls)
      .then(
        axios.spread((...res) => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          let returnedSurcharges = res.map((r: any, i) => {
            if (r === null) {
              return {
                invoiceToken: payloadArray[i].invoiceToken,
                surchargeAmount: null,
              };
            }
            r.data.data.invoiceToken = payloadArray[i].invoiceToken;
            if (r.data.data.isSurchargeable) {
              return r.data.data;
            } else {
              r.data.data.surchargeAmount = 0;
              return r.data.data;
            }
          });

          if (
            returnedSurcharges.some((r) => {
              return r.surchargeAmount === null;
            })
          ) {
            setModalSurchargeError(true);
            setShowSurchargeModal(true);
          } else {
            setModalSurchargeError(false);
          }
          return returnedSurcharges;
        })
      )
      .catch(() => {
        setModalSurchargeError(true);
        setShowSurchargeModal(true);
      });
  };

  const getSurchargeAmount = async (idArray: string[], inputValue?: number | null, recalculating?: boolean) => {
    let invoicesFound = originalResponse.filter((row) => idArray.includes(row.invoiceId));
    if (invoicesFound && typeof binFilled === 'string') {
      let payloads = invoicesFound.map((invoice) => {
        return {
          invoiceToken: invoice.invoiceToken,
          creditCardBin: binFilled,
          state: surchargeState,
          baseAmount: recalculating
            ? modalAmounts[invoice.invoiceId]
            : inputValue !== undefined
            ? inputValue
            : invoice.total,
          addTaxToTotal: false,
          taxAmount: invoice.tax,
          processorId: invoice.invoicePaymentMethod.cardProcessorId,
        };
      });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const surchargeResponse: any = await calculateFees(payloads);

      let updatedRows: CustomerPayableInterface[] = [];
      let updatedSurcharge = { ...modalSurcharges };
      modalRows.forEach((row) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let foundSurchargeResponse = surchargeResponse.find((r: any) => r.invoiceToken === row.invoiceToken);
        if (foundSurchargeResponse) {
          //removing rows as selected if surcharge amount could not be calculated
          if (foundSurchargeResponse.surchargeAmount === null) {
            setModalAmounts({ ...modalAmounts, [row.invoiceId![1]]: '0.00' });
          }
          updatedSurcharge[row.invoiceId![1]] = foundSurchargeResponse.surchargeAmount;
        }

        updatedRows.push(row);
      });
      setModalSurcharges(updatedSurcharge);
      setModalRows(updatedRows);
      setModalSurchargeLoading(false);
    }
  };

  return (
    <>
      <AlertModal
        tabOpen={showSurchargeModal}
        setTabOpen={setShowSurchargeModal}
        alertTitle=''
        alertText={
          'One or more invoices have been disabled due to an error calculating surcharge fees. You may continue with any remaining invoices or refresh the page to try again.'
        }
        alertIcon='warning'
        onConfirm={() => {
          setShowSurchargeModal(false);
        }}
        onClose={() => navigate(0)}
        confirmButtonText='Continue with Remaining Invoices'
        cancelButtonText='Refresh Page'
      ></AlertModal>
      <Dialog
        open={tabOpen}
        onClose={() => {
          setSubmitClicked(false);
          setOpenSubmitModal(false);
          setDisableSubmit(false);
        }}
        aria-labelledby='alert-dialog-title'
        className={theme.palette.mode === 'light' ? 'invoice-submit-modal' : 'invoice-submit-modal dark-theme '}
        sx={{
          container: { margin: 0, padding: 0 },
        }}
      >
        <Grid container sx={{ padding: 1.5 }}>
          <Grid
            item
            xs
            id='alert-dialog-title'
            sx={{
              fontSize: '20px',
              color: 'alertDialog.main',
              textAlign: 'center',
              wordBreak: 'keep-all',
            }}
          >
            Invoices Selected to Pay
          </Grid>
        </Grid>

        <Table>
          <TableContainer>
            <TableHead className='submit-modal-header'>
              <InvoiceHeaderRow isModal={true} surcharge={surcharge} paymentTab={paymentTab}></InvoiceHeaderRow>
            </TableHead>

            <TableBody>
              {modalRows.map((row, i) => (
                <TableRow
                  key={i}
                  className={
                    modalSurcharges[row.invoiceId![1]] === null ? ' submit-modal-row disabled-row' : 'submit-modal-row'
                  }
                >
                  <TableCell>
                    <Tooltip title='Delete'>
                      <IconButton
                        sx={{ padding: '10px' }}
                        disabled={modalSurcharges[row.invoiceId![1]] === null}
                        onClick={() => {
                          setModalRows(modalRows.filter((r) => row.invoiceId![1] !== r.invoiceId![1]));
                          let newAmounts = { ...modalAmounts };
                          delete newAmounts[row.invoiceId![1]];
                          let newSurcharges = { ...modalSurcharges };
                          delete newSurcharges[row.invoiceId![1]];
                          setModalAmounts(newAmounts);
                          setModalSurcharges(newSurcharges);
                        }}
                        color={'error'}
                      >
                        <CancelIcon fontSize='small' />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                  <InvoiceTableCells
                    type={'modal'}
                    row={row}
                    surchargeList={modalSurcharges}
                    amountList={modalAmounts}
                    setAmountList={setModalAmounts}
                    getSurchargeAmount={getSurchargeAmount}
                    disableModalSubmit={disableModalSubmit}
                    setDisableModalSubmit={setDisableModalSubmit}
                    setModalSurchargeLoading={setModalSurchargeLoading}
                    modalErrorList={modalErrorList}
                    setModalErrorList={setModalErrorList}
                    setModalSurcharges={setModalSurcharges}
                  />
                </TableRow>
              ))}
            </TableBody>
            <InvoiceListTotalsRow
              displayedRows={modalRows}
              totalAmountToPay={totalAmountToPay}
              totalSurcharge={totalSurcharge}
            />
          </TableContainer>
        </Table>
        {modalSurchargeError && (
          <Grid container flexDirection={'row'} className='max-invoice-div'>
            <Grid item xs={0.5}>
              <ErrorIcon className='warning' />
            </Grid>
            <Grid item xs={11.5}>
              One or more invoices have been disabled due to an error calculating surcharge fees. You may continue with
              any remaining invoices or refresh the page to try again.
            </Grid>
          </Grid>
        )}
        <DialogActions>
          <Grid container direction='row' justifyContent='center' spacing={1.5} alignContent='center'>
            <Grid item>
              <Button
                variant='outlined'
                color='secondary'
                fullWidth
                onClick={() => {
                  setSubmitClicked(false);
                  setOpenSubmitModal(false);
                  setDisableSubmit(false);
                }}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant='contained'
                color='secondary'
                onClick={onConfirm}
                fullWidth
                autoFocus
                disabled={
                  disableModalSubmit || modalRows.length < 1 || modalSurchargeLoading || modalErrorList.length !== 0
                }
              >
                Submit Payment(s)
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default InvoicesSubmitModal;
