import colors from './sass/abstracts/colors.module.scss';
import { PaletteMode } from '@mui/material';

// Had to remove several properties from this object to get it to match the MUI types

const light = {
  mode: 'light' as PaletteMode,
  primary: {
    light: colors.blue200,
    main: colors.blue600,
    dark: colors.blue800,
  },
  secondary: {
    light: colors.blue200,
    main: colors.blue300,
    dark: colors.blue400,
    contrastText: colors.system_white,
  },
  warning: {
    light: colors.yellow200,
    main: colors.yellow300,
    dark: colors.warning_dark,
  },
};

const dark = {
  mode: 'dark' as PaletteMode,
  primary: {
    light: colors.blue200,
    main: colors.blue550,
    dark: colors.blue800,
  },
  secondary: {
    light: colors.blue400,
    main: colors.blue550,
    dark: colors.blue400,
    contrastText: colors.system_white,
  },
  warning: {
    light: colors.yellow100,
    main: colors.yellow100,
    dark: colors.warning_dark,
  },
  error: {
    main: colors.error,
  },
};

export const getPalette = (mode: PaletteMode) => ({
  palette: {
    ...(mode === 'light' ? light : dark),
  },
});

export default getPalette;
