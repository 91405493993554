/* eslint-disable prefer-const */
import { useState, useRef, useEffect, useContext } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Card, IInvoiceResponse } from '../../../core/interfaces';
import { useFormContext } from 'react-hook-form';
import TokenExComponent from '../../Global/Tokenizer/TokenEx';
import ACHTokenizerComponent from '../../Global/Tokenizer/IqProACHTokenizer';
import PaymentAddressWrapper from '../Payment/PaymentAddresses';
import { ITokenInterface } from '../Payment/Payment';
import CheckIcon from '@mui/icons-material/Check';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Dispatch, SetStateAction } from 'react';
import AlertModal from '../../Global/Tokenizer/AlertModal';
import { CustomerPayablesContext, CustomerPayablesContextType } from '../../../contexts/CustomerPayablesContext';

type CustomerPayablesPaymentInformationProps = {
  invoiceDetail: IInvoiceResponse | undefined;
  handlePayment: (payload: ITokenInterface, modal: boolean) => void;
  invoiceToken: string | undefined;
  errorMessage: string;
  setErrorMessage: Dispatch<SetStateAction<string>>;
  setPaymentTab?: Dispatch<SetStateAction<string>>;
  handleSwitchPayment: (tab: string) => void;
  setInvoiceSubmit: Dispatch<SetStateAction<boolean>>;
  setMaskedPayment: Dispatch<SetStateAction<string>>;
};

export interface ICalculateFees {
  creditCardBin: string;
  state: string;
  baseAmount: number;
  addTaxToTotal: boolean;
  taxAmount?: number;
  processorId: string;
}

const CustomerPayablesPaymentInformation = ({
  invoiceDetail,
  handlePayment,
  invoiceToken,
  errorMessage,
  setErrorMessage,
  setInvoiceSubmit,
  setMaskedPayment,
}: CustomerPayablesPaymentInformationProps) => {
  const {
    selectedRows,
    surcharge,
    disableSubmit,
    setDisableSubmit,
    displayedRows,
    tokenConfiguration,
    setDisplayedRows,
    setBinFilled,
    setPaymentTab,
    handleSwitchPayment,
    loadingSurcharge,
    setOpenSubmitModal,
    selectedPagesCounter,
    page,
    invoiceSubmit,
    summary,
    submitClicked,
    setSubmitClicked,
    setSurchargeState,
    errorList,
  } = useContext(CustomerPayablesContext) as CustomerPayablesContextType;
  const methods = useFormContext();
  const {
    formState,
    formState: { errors, isValid },
  } = useFormContext();
  const [activeTab, setActiveTab] = useState(1);
  const [token, setToken] = useState<ITokenInterface | null>(null);
  const [showTabChangeModal, setShowTabChangeModal] = useState(false);
  const [addressErrors, setAddressErrors] = useState<{ billing: boolean; shipping: boolean }>({
    billing: false,
    shipping: false,
  });
  const [disabledFields, setDisabledFields] = useState<string[]>([]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const cardRef = useRef<any>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const achRef = useRef<any>();
  const disableSubmitCondition =
    disableSubmit || loadingSurcharge || selectedRows.length === 0 || errorList.length !== 0;

  useEffect(() => {
    if (invoiceSubmit) {
      if (token) {
        handlePayment(token, invoiceSubmit);
        setInvoiceSubmit(false);
      } else {
        handleSubmission();
      }
    }
  }, [invoiceSubmit]);

  useEffect(() => {
    if (loadingSurcharge) {
      setDisabledFields(['state']);
    } else {
      setDisabledFields([]);
    }
  }, [loadingSurcharge]);

  const handleStateChange = (state: string) => {
    setSurchargeState(state);
  };

  const handleTokenExSubmit = (value: Card, exp: string | null) => {
    setToken({
      token: value.token,
      expirationDate: exp || '',
      maskedNumber: value.firstSix + '******' + value.lastFour,
      paymentType: 'card',
    });
    setMaskedPayment(value.firstSix + '******' + value.lastFour);
  };

  const handleTokenExValid = (value: string | null) => {
    if (value) {
      if (setBinFilled) {
        setBinFilled(value);
      }
    } else if (setBinFilled) {
      setBinFilled(false);
    }
  };

  const handleACHSubmit = (value: string) => {
    setToken({
      token: value,
      paymentType: 'ach',
    });
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    if (selectedRows.length > 0) {
      setShowTabChangeModal(true);
    } else {
      setActiveTab(newValue);
      setPaymentTab!(newValue === 1 ? 'card' : 'ach');
      if (surcharge && newValue === 1) {
        let newRows = displayedRows.map((row) => {
          return { ...row, surcharge: 0, total: 0 };
        });
        setDisplayedRows(newRows);
      } else if (newValue === 2) {
        displayedRows.forEach((row) => {
          delete row.surcharge;
          delete row.total;
        });
        setDisplayedRows(displayedRows);
      }
    }
  };

  const handleSubmission = () => {
    activeTab === 1 ? cardRef.current!.validate() : achRef.current!.validate();
  };

  //useEffect needed to track formstate errors, won't show updated errors unless I do this
  useEffect(() => {
    if (submitClicked) {
      setAddressErrors({
        billing: Object.hasOwn(errors, 'billing'),
        shipping: Object.hasOwn(errors, 'shipping'),
      });
      if (isValid) {
        handleSubmission();
        setSubmitClicked(false);
      } else {
        setErrorMessage('Please fill all required fields');
        setDisableSubmit(false);
        setSubmitClicked(false);
      }
    }
  }, [formState]);

  useEffect(() => {
    if (submitClicked) {
      methods.trigger();
    }
  }, [submitClicked]);

  const submitPaymentClicked = async (event: React.SyntheticEvent) => {
    setDisableSubmit(true);
    event?.preventDefault();
    setSubmitClicked(true);
  };

  useEffect(() => {
    if (token) {
      if (selectedPagesCounter[page] < selectedPagesCounter.total && disableSubmit && !invoiceSubmit) {
        setOpenSubmitModal(true);
      } else {
        handlePayment(token, invoiceSubmit);
        setInvoiceSubmit(false);
      }
    }
  }, [token]);

  const handleTokenExError = () => {
    setDisableSubmit(false);
  };
  const handleAchError = () => {
    setDisableSubmit(false);
  };

  return (
    <div className='payment-info-container'>
      <AlertModal
        tabOpen={showTabChangeModal}
        setTabOpen={setShowTabChangeModal}
        alertTitle='Are you sure?'
        alertText={'Invoice selection may be lost due to change in payment method. Do you wish to continue?'}
        alertIcon='warning'
        onConfirm={() => {
          setShowTabChangeModal(false);
          setActiveTab(activeTab === 1 ? 2 : 1);
          setPaymentTab!(activeTab === 1 ? 'ach' : 'card');
          handleSwitchPayment(activeTab === 1 ? 'ach' : 'card');
        }}
        confirmButtonText='Yes, change payment method'
      ></AlertModal>
      <div className='payment-section'>
        <h2>Your Bank Payment Information</h2>
        <Tabs className='primary' value={activeTab} onChange={handleTabChange} aria-label='basic tabs example'>
          <Tab iconPosition='start' icon={<CheckIcon />} value={1} label='Card' />
          <Tab iconPosition='start' icon={<CheckIcon />} value={2} label='Bank Account' />
        </Tabs>

        <div hidden={activeTab !== 1} className={loadingSurcharge ? 'disable-tokenizer' : ''}>
          <div id='tokenizer-card'></div>
          <TokenExComponent
            handleError={handleTokenExError}
            tokenConfig={tokenConfiguration!.iframeConfiguration!.iqProV2!}
            handleSubmit={handleTokenExSubmit}
            tokenizerRef={cardRef}
            validCard={handleTokenExValid}
            iqProToken={invoiceToken}
          />
        </div>
        <div hidden={activeTab !== 2}>
          <div>
            {activeTab === 2 && (
              <ACHTokenizerComponent
                handleError={handleAchError}
                SECCode={2}
                iqProToken={invoiceToken}
                tokenizerRef={achRef}
                handleSubmit={handleACHSubmit}
                setMaskedPayment={setMaskedPayment}
              />
            )}
          </div>
        </div>

        <PaymentAddressWrapper
          invoiceDetail={invoiceDetail}
          customerRequireShipping={summary.requireShippingInfo! || false}
          addressErrors={addressErrors}
          handleStateChange={handleStateChange}
          disabledFields={disabledFields}
          emailReceipt={true}
        />

        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <button
            type='submit'
            onClick={submitPaymentClicked}
            className={disableSubmitCondition ? 'primary disabled' : 'primary'}
            disabled={disableSubmitCondition}
          >
            Submit Payment
          </button>
        </div>
      </div>
      {errorMessage && (
        <div className='error-banner'>
          <ErrorOutlineIcon /> {errorMessage}
        </div>
      )}
      <p className='disclaimer'>
        By clicking "Submit Payment" you authorize {invoiceDetail?.gateway.name} to charge your Payment Method
      </p>
    </div>
  );
};

export default CustomerPayablesPaymentInformation;
