import { Grid } from '@mui/material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

const InvoiceStatusLegend = () => {
  return (
    <Grid container flexDirection={'row'} className='invoice-status-legend'>
      <Grid item style={{ fontWeight: '600' }}>
        STATUS
      </Grid>
      <Grid item>
        <Grid container alignItems={'center'}>
          <FiberManualRecordIcon
            fontSize='inherit'
            htmlColor={'#4CAF50'}
            style={{ margin: '0 7 0 5', alignSelf: 'center' }}
          />
          Due
        </Grid>
      </Grid>
      <Grid item>
        <Grid container alignItems={'center'}>
          <FiberManualRecordIcon
            fontSize='inherit'
            htmlColor={'#ED6C02'}
            style={{ margin: '0 7 0 5', alignSelf: 'center' }}
          />
          Partially Paid
        </Grid>
      </Grid>
      <Grid item>
        <Grid container alignItems={'center'}>
          <FiberManualRecordIcon
            fontSize='inherit'
            htmlColor={'#D32F2F'}
            style={{ margin: '0 7 0 5', alignSelf: 'center' }}
          />
          Overdue
        </Grid>
      </Grid>
    </Grid>
  );
};

export default InvoiceStatusLegend;
