import { TextField } from '@mui/material';
import { UseFormRegisterReturn } from 'react-hook-form';

type SimpleTextFieldProps = {
  registration: UseFormRegisterReturn<string>;
  error: boolean | undefined;
  label: string;
  id: string;
  asterisk?: boolean | undefined;
};

const SimpleTextField = ({ registration, error, label, id, asterisk }: SimpleTextFieldProps) => {
  return (
    <div className='field-wrapper'>
      <label>
        {label}
        {asterisk && <div className='requiredAsterisk'>*</div>}
      </label>
      <TextField {...registration} error={error} className='text-input' id={id}></TextField>
    </div>
  );
};

export default SimpleTextField;
