import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { Grid } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';
import ErrorIcon from '@mui/icons-material/Error';
import './alertModal.scss';

type AlertModalProps = {
  tabOpen: boolean;
  setTabOpen: Dispatch<SetStateAction<boolean>>;
  alertTitle: string;
  alertIcon?: string;
  alertText: string;
  onConfirm?: () => void;
  onClose?: () => void;
  confirmButtonText?: string;
  cancelButtonText?: string;
};
const AlertModal = ({
  tabOpen,
  setTabOpen,
  alertTitle,
  alertIcon,
  alertText,
  onConfirm,
  onClose,
  confirmButtonText,
  cancelButtonText,
}: AlertModalProps) => {
  return (
    <Dialog
      open={tabOpen}
      onClose={() => setTabOpen(false)}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      className='alert-modal'
      sx={{
        container: { margin: 0, padding: 0 },
      }}
    >
      <Grid container>
        <Grid
          item
          xs
          sx={{
            fontSize: '1.85em',
            color: 'alertDialog.main',
            textAlign: 'center',
            wordBreak: 'keep-all',
          }}
        >
          {alertTitle}
        </Grid>
      </Grid>
      {alertIcon && (
        <Grid container>
          <Grid item xs={12}>
            {alertIcon && (
              <div>
                <ErrorIcon color='warning' sx={{ fontSize: '3.5em' }} />
              </div>
            )}
          </Grid>
        </Grid>
      )}
      <DialogContent>
        <DialogContentText sx={{ textAlign: 'center' }} id='alert-dialog-description'>
          {alertText}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Grid container direction='column' justifyContent='center' spacing={1.5} alignContent='center'>
          {onConfirm && (
            <Grid item>
              <Button variant='contained' color='secondary' onClick={onConfirm} fullWidth autoFocus>
                {confirmButtonText ? confirmButtonText : 'Confirm'}
              </Button>
            </Grid>
          )}
          <Grid item>
            <Button
              variant='outlined'
              color='secondary'
              fullWidth
              onClick={() => {
                onClose ? onClose() : setTabOpen(false);
              }}
            >
              {cancelButtonText ? cancelButtonText : 'Cancel'}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};
export default AlertModal;
