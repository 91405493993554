import cancelledError from '../../assets/images/payment/payment-cancelled.png';
type SimplePaymentErrorProps = {
  errorCode: number;
  isPayable?: boolean;
};

export interface IERROR {
  [key: number]: {
    header: string;
    image: string;
    message: string;
  };
}

export const SIMPLE_ERROR_CODES: IERROR = {
  401: {
    header: 'Unauthorized.',
    image: cancelledError,
    message: 'This simple payment token cannot be found. Please contact support for further assistance.',
  },
  403: {
    header: 'This simple payment has been cancelled.',
    image: cancelledError,
    message: 'This simple payment has been cancelled. Please contact support for further assistance.',
  },
  404: {
    header: 'This simple payment cannot be found.',
    image: cancelledError,
    message: 'This simple payment cannot be found. Please contact support for further assistance.',
  },
};

const SimplePaymentError = ({ errorCode }: SimplePaymentErrorProps) => {
  const code = SIMPLE_ERROR_CODES[errorCode];
  return (
    <div className='invoice-error-wrapper'>
      <h1> {code?.header}</h1>
      <div>
        <img src={code?.image} />
      </div>
      <p>{code.message}</p>
    </div>
  );
};

export default SimplePaymentError;
