import React from 'react';
import { Dispatch, SetStateAction } from 'react';
import { ITokenizerConfig } from '../core/interfaces';
import { IGetPayableResponse, IResponseSummary } from '../core/interfaces/customerPayables.interface';
import { CustomerPayableInterface } from '../components/Invoicing/CustomerPayables/CustomerPayables';

export type CustomerPayablesContextType = {
  displayedRows: CustomerPayableInterface[];
  selectedRows: CustomerPayableInterface[];
  surcharge: boolean;
  paymentTab: string;
  savedSurcharges: { [key: string]: number };
  setSavedSurcharges: Dispatch<SetStateAction<{ [key: string]: number }>>;
  amountToPay: { [key: string]: string };
  originalResponse: IGetPayableResponse[];
  binFilled: string | boolean;
  modalAmounts: { [key: string]: string };
  modalRows: CustomerPayableInterface[];
  modalSurcharges: { [key: string]: number };
  disableSubmit: boolean;
  setDisableSubmit: Dispatch<SetStateAction<boolean>>;
  tokenConfiguration: ITokenizerConfig | null;
  setDisplayedRows: Dispatch<SetStateAction<CustomerPayableInterface[]>>;
  setBinFilled?: Dispatch<SetStateAction<boolean | string>>;
  setPaymentTab?: Dispatch<SetStateAction<string>>;
  handleSwitchPayment: (tab: string) => void;
  loadingSurcharge: boolean;
  setOpenSubmitModal: Dispatch<SetStateAction<boolean>>;
  selectedPagesCounter: { [page: number]: number; total: number };
  page: number;
  invoiceSubmit: boolean;
  setLoadingSurcharge: Dispatch<SetStateAction<boolean>>;
  summary: IResponseSummary;
  setInvoiceSubmit: Dispatch<SetStateAction<boolean>>;
  submitClicked: boolean;
  setSubmitClicked: Dispatch<SetStateAction<boolean>>;
  surchargeState: string;
  setSurchargeState: Dispatch<SetStateAction<string>>;
  errorList: string[];
  setErrorList: Dispatch<SetStateAction<string[]>>;
};
export const CustomerPayablesContext = React.createContext<CustomerPayablesContextType | null>(null);
