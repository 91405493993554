import { IInvoiceResponse } from '../../../core/interfaces';
import { formatTransactionCurrency } from '../../../core/helpers/stringHelpers';
import { useState } from 'react';
import InvoicePaymentHistory from './PaymentHistoryModal';
type Summaryprops = {
  invoiceDetails: IInvoiceResponse;
};

const InvoicePaymentSummary = ({ invoiceDetails }: Summaryprops) => {
  const [isOpen, setOpenModal] = useState<boolean>(false);

  const openModal = () => {
    setOpenModal(true);
  };
  const handleClose = () => {
    setOpenModal(false);
  };
  return (
    <div className='payment-summary-container'>
      <div className='summary-header'>
        <h2>Invoice Summary</h2>
        {invoiceDetails.transactions.length > 0 && (
          <button type='button' onClick={openModal} className='link-btn'>
            View Prior Transaction History
          </button>
        )}
      </div>

      <div className='summary-table'>
        <table>
          <thead>
            <tr>
              <th>Details</th>
              <th style={{ textAlign: 'center' }}>Quantity</th>
              <th style={{ textAlign: 'center' }}>Unit Price</th>
              <th style={{ textAlign: 'center' }}>Total</th>
            </tr>
          </thead>

          <tbody>
            {invoiceDetails.lineItems.map((item, i) => {
              return (
                <tr key={i} className='invoice-row'>
                  <td>
                    {item.name}
                    <p>{item.description}</p>
                  </td>
                  <td style={{ textAlign: 'center', verticalAlign: 'initial', paddingTop: '10px' }}>{item.quantity}</td>
                  <td style={{ textAlign: 'center', verticalAlign: 'initial', paddingTop: '10px' }}>
                    {formatTransactionCurrency(item.unitPrice)}
                  </td>
                  <td style={{ textAlign: 'center', verticalAlign: 'initial', paddingTop: '10px' }}>
                    {formatTransactionCurrency(item.quantity * item.unitPrice)}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className='summary-totals'>
        <div>
          <div className='summary-col-wrapper'>
            <div className='total-col'>
              <div>Subtotal:</div>
              {invoiceDetails.discounts > 0 && <div>Discounts:</div>}
              <div>Taxes:</div>
              {invoiceDetails?.shipping > 0 && <div>Shipping:</div>}

              {invoiceDetails.capturedAmount > 0 && <div>Amount Paid:</div>}
            </div>
            <div className='total-col'>
              <div>{formatTransactionCurrency(invoiceDetails.subtotal)}</div>
              {invoiceDetails.discounts > 0 && <div>-{formatTransactionCurrency(invoiceDetails.discounts)}</div>}
              <div>{formatTransactionCurrency(invoiceDetails.tax)}</div>
              {invoiceDetails?.shipping > 0 && <div>{formatTransactionCurrency(invoiceDetails.shipping)}</div>}

              {invoiceDetails.capturedAmount > 0 && (
                <div>({formatTransactionCurrency(invoiceDetails.capturedAmount)})</div>
              )}
            </div>
          </div>

          {/* <div className='sub-row'>
            <span>Subtotal:</span> <span>{formatTransactionCurrency(invoiceDetails.subtotal)}</span>
          </div>
          <div className='sub-row'>
            <span>Taxes:</span> <span>{formatTransactionCurrency(invoiceDetails.tax)}</span>
          </div> */}
          <div className='total-row'>
            <span>Balance Due:</span> <span>{formatTransactionCurrency(invoiceDetails.remainingBalance)}</span>
          </div>
        </div>
      </div>
      <InvoicePaymentHistory handleClose={handleClose} invoiceDetails={invoiceDetails} isOpen={isOpen} />
    </div>
  );
};

export default InvoicePaymentSummary;
