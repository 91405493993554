import { useContext } from 'react';
import { LayoutContext } from '../../contexts/LayoutContext';

type SimplePaymentHeaderProps = { simplePayName: string };

const SimplePaymentHeader = ({ simplePayName }: SimplePaymentHeaderProps) => {
  const layoutContext = useContext(LayoutContext);
  return (
    <div className={'heading-container' + (layoutContext?.layout?.isHeaderCentered ? '-centered-header' : '')}>
      <div className='heading-container-left-col' />
      <div className='heading-container-right-col'>
        <h1>
          <strong>{simplePayName}</strong>
        </h1>
      </div>
    </div>
  );
};

export default SimplePaymentHeader;
