import { IInvoiceResponse } from '../../../core/interfaces';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { formatDateTime, formatTransactionCurrency } from '../../../core/helpers/stringHelpers';
import Tooltip from '@mui/material/Tooltip';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { useTheme } from '@mui/material/styles';
type HistoryProps = {
  invoiceDetails: IInvoiceResponse;
  isOpen: boolean;
  handleClose: () => void;
};

const InvoicePaymentHistory = ({ invoiceDetails, isOpen, handleClose }: HistoryProps) => {
  const copyToClipBoard = (id: string) => {
    navigator.clipboard.writeText(id);
  };
  const theme = useTheme();

  return (
    <Dialog className={theme.palette.mode + '-theme' + ' history-dialog-wrapper'} onClose={handleClose} open={isOpen}>
      <div className='close-btn'>
        <IconButton onClick={handleClose}>
          <CloseIcon fontSize='small' />
        </IconButton>
      </div>
      <div className='body-wrapper'>
        <h3>Prior Transaction History</h3>

        <div className='totals-summary'>
          <div>
            <span>Amount Paid:</span>
            <span>{formatTransactionCurrency(invoiceDetails.capturedAmount)}</span>
          </div>
          <div>
            <span>Remaining Balance:</span>
            <span>{formatTransactionCurrency(invoiceDetails.remainingBalance)}</span>
          </div>
        </div>
        <table>
          <thead>
            <tr>
              <th>Transaction ID</th>
              <th>Payment Amount</th>
              <th>Payment Method</th>
              <th>Payment Date</th>
            </tr>
          </thead>
          <tbody>
            {invoiceDetails.transactions.map((transaction, i) => {
              return (
                <tr key={i}>
                  <td style={{ textAlign: 'center' }}>
                    {' '}
                    <IconButton
                      onClick={() => {
                        copyToClipBoard(transaction.transactionId);
                      }}
                      className='copy-btn'
                    >
                      <FileCopyIcon fontSize='small' />
                    </IconButton>{' '}
                    <Tooltip title={'Click icon to copy to clipboard ' + transaction.transactionId}>
                      <span>{transaction.transactionId.slice(0, 6)}...</span>
                    </Tooltip>
                  </td>
                  <td style={{ textAlign: 'center' }}>{formatTransactionCurrency(transaction.remit.capturedAmount)}</td>
                  <td style={{ textAlign: 'center' }}>{transaction.paymentType}</td>
                  <td style={{ textAlign: 'center' }}>{formatDateTime(transaction.createdDateTime).split(' ')[0]}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </Dialog>
  );
};

export default InvoicePaymentHistory;
