import { CONTACT_SUPPORT } from '../../core/constants/global';
import empty from '../../assets/images/empty-state.png';

const Error = () => {
  return (
    <div className='error-container'>
      <div className='centered-block'>
        <div className='centered-image'>
          <img src={empty} />
        </div>
        <p>
          The server encountered an error and could <br /> not complete your request.
        </p>
        <div>
          <div className='error-information'>
            <p>Correlation ID: CHANGE_ME</p>
            <p>Timestamp: {new Date().toISOString().replace('T', ' ')}</p>
            <p>{CONTACT_SUPPORT}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Error;
