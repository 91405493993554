import { useImperativeHandle } from 'react';
import { MenuItem, TextField } from '@mui/material';
import { Controller } from 'react-hook-form';
import { useFormContext } from 'react-hook-form';
import MUISelect from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import axios, { AxiosResponse } from 'axios';
import { regexPatterns } from '../../../core/constants/regexPatterns';
import { validationRules } from '../../../core/constants/validationRules';
import { ACHTokenizerProps } from './tokenizerHelpers';
import { IHTTPInterface } from '../../../core/interfaces/http.interface';
import { invoiceEndpoints } from '../../../core/constants/endpoints';

export enum secCode {
  PPD = 0,
  CCD = 1,
  WEB = 2,
  TEL = 3,
}
const ACHTokenizerComponent = ({
  handleSubmit,
  handleError,
  tokenizerRef,
  iqProToken,
  SECCode,
  setMaskedPayment,
}: ACHTokenizerProps) => {
  useImperativeHandle(tokenizerRef, () => ({
    validate() {
      generateToken();
    },
  }));
  const methods = useFormContext();

  const generateToken = () => {
    const url: string = invoiceEndpoints.tokenizeACH;
    const payload: object = {
      accountNumber: methods.getValues().ach_account_number,
      routingNumber: methods.getValues().ach_routing_number,
      secCode: secCode[SECCode],
      achAccountType: methods.getValues().ach_account_type,
    };
    axios
      .post(url, payload, { headers: { Authorization: iqProToken } })
      .then((response: AxiosResponse<IHTTPInterface>) => {
        if (setMaskedPayment !== undefined) {
          setMaskedPayment(response?.data?.data?.maskedAccount);
        }
        handleSubmit(response?.data?.data?.achId);
      })
      .catch(() => {
        handleError();
      });
  };

  return (
    <>
      <div className='ach-form'>
        <TextField
          className='text-input'
          id='achRoutingNumber'
          fullWidth
          placeholder={'Routing Number *'}
          {...methods.register('ach_routing_number', {
            required: { value: true, message: 'required' },
            pattern: {
              value: regexPatterns.numeric,
              message: 'Only numbers allowed',
            },
            minLength: validationRules.minLength9,
            maxLength: validationRules.maxLength9,
          })}
          error={methods.formState.errors['ach_routing_number'] !== undefined}
          helperText={
            methods.formState.errors['ach_routing_number'] &&
            methods.formState.errors['ach_routing_number']?.message?.toString()
          }
        ></TextField>
        <TextField
          className='text-input'
          id='achAccountNumber'
          placeholder={'Bank Account Number *'}
          fullWidth
          {...methods.register('ach_account_number', {
            required: { value: true, message: 'required' },
            pattern: {
              value: regexPatterns.numeric,
              message: 'Only numbers allowed',
            },
            maxLength: validationRules.maxLength19,
          })}
          error={methods.formState.errors['ach_account_number'] !== undefined}
          helperText={
            methods.formState.errors['ach_account_number'] &&
            methods.formState.errors['ach_account_number']?.message?.toString()
          }
        ></TextField>

        <FormControl fullWidth required={true} {...methods.register('ach_account_type')}>
          <Controller
            name={'ach_sec_code'}
            defaultValue={'Checking'}
            control={methods.control}
            render={({ field }) => (
              <MUISelect
                className='text-input'
                defaultValue={'Checking'}
                onChange={(e) => {
                  field.onChange(e.target.value);
                }}
                id='achAccountType'
                value={field.value}
              >
                <MenuItem key={`0`} value={'Checking'}>
                  Checking
                </MenuItem>
                <MenuItem key={`1`} value={'Savings'}>
                  Savings
                </MenuItem>
              </MUISelect>
            )}
          />
        </FormControl>
      </div>
    </>
  );
};

export default ACHTokenizerComponent;
