import { createTheme } from '@mui/material/styles';
import { PaletteMode } from '@mui/material';
import getPalette from '../assets/palettes';

const getTheme = (mode: PaletteMode) => {
  const palette = getPalette(mode);
  const theme = createTheme(palette);

  return theme;
};

export default getTheme;
