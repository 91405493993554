import { useState, useRef, useEffect } from 'react';
import { InputAdornment, TextField, useTheme } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { convertToInt, formatNumber, formatTransactionCurrency } from '../../core/helpers/stringHelpers';
import { invoiceEndpoints } from '../../core/constants/endpoints';
import { stringFormat } from '../Global/Tokenizer/tokenizerHelpers';
import axios, { AxiosResponse } from 'axios';
import { IHTTPInterface, ISurchargeResponse } from '../../core/interfaces';
import { useDebounce } from '../../hooks/useDebounce';
import { ISimplePayConfiguration } from '../../core/interfaces/simplepay.interface';

type simplePaymentAmountProps = {
  simpleConfiguration: ISimplePayConfiguration;
  bin: string;
  simpleToken: string;
  surchargeState: string;
  surchargeResponse: ISurchargeResponse | null;
  setSurchargeResponse: React.Dispatch<React.SetStateAction<ISurchargeResponse | null>>;
  setPaymentMethodAccepted: React.Dispatch<React.SetStateAction<boolean>>;
  setDisabledButton: React.Dispatch<React.SetStateAction<boolean>>;
  activeTab: number;
};

interface ICalculateFees {
  creditCardBin: string;
  state: string;
  baseAmount: number;
  addTaxToTotal: boolean;
  taxAmount?: number;
}

const SimplePaymentAmount = ({
  simpleConfiguration,
  bin,
  simpleToken,
  surchargeState,
  surchargeResponse,
  setSurchargeResponse,
  setPaymentMethodAccepted,
  setDisabledButton,
  activeTab,
}: simplePaymentAmountProps) => {
  const methods = useFormContext();
  const [paymentAmount, setPaymentAmount] = useState<string>('0.00');
  const debouncedAmount = useDebounce<string>(paymentAmount, 1000);
  const isDarkTheme = useTheme().palette.mode === 'dark';

  const handleCurrencyPipe = (value: string) => {
    // no more than 7 figures to match iqpro+ client
    if (value.length > 12) {
      value = value.substring(0, 12);
    }
    // clean up commas to be valid number
    const cleanVal: number = parseFloat(value.replace(/[.,\s]/g, ''));
    const formattedNumber: string = formatNumber(convertToInt(+cleanVal), 2, '0,0.00');
    setPaymentAmount(formattedNumber);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const inputRef = useRef<any>();

  const moveInputPositionToEnd = () => {
    inputRef.current!.setSelectionRange(Number.MAX_SAFE_INTEGER, Number.MAX_SAFE_INTEGER);
  };

  const calculateFees = () => {
    setDisabledButton(true);
    const url: string = stringFormat(invoiceEndpoints.calculateFees, [simpleToken!]);
    const payload: ICalculateFees = {
      creditCardBin: bin,
      state: surchargeState,
      baseAmount: parseFloat(paymentAmount.replace(/[,\s]/g, '')),
      addTaxToTotal: false,
      taxAmount: 0, //TODO
    };
    axios
      .post(url, payload, { headers: { Authorization: simpleToken } })
      .then((res: AxiosResponse<IHTTPInterface>) => {
        interface cardSettingsDictionary {
          [key: string]: boolean;
        }
        const cardSettingsMap: cardSettingsDictionary = {
          AmericanExpress: simpleConfiguration.cardSettings.acceptAmex,
          MasterCard: simpleConfiguration.cardSettings.acceptMastercard,
          Visa: simpleConfiguration.cardSettings.acceptVisa,
          Discover: simpleConfiguration.cardSettings.acceptDiscover,
        };
        setPaymentMethodAccepted(cardSettingsMap[res.data.data.cardBrand]);
        setSurchargeResponse(res.data.data.isSurchargeable ? res.data.data : null);
      })
      .catch(() => {
        setSurchargeResponse(null);
      })
      .finally(() => {
        setDisabledButton(false);
      });
  };
  useEffect(() => {
    if (bin && surchargeState && activeTab === 1) {
      calculateFees();
    }
  }, [bin, surchargeState, debouncedAmount, activeTab]);

  return (
    <div className={'payment-info-container'}>
      <div
        className={'payment-section'}
        style={{
          backgroundColor: isDarkTheme ? '#121212' : simpleConfiguration.brandingSettings.paymentColor || '#d4effa',
          backgroundImage: isDarkTheme
            ? 'linear-gradient(rgba(255, 255, 255, 0.16), rgba(255, 255, 255, 0.16))'
            : 'none',
        }}
      >
        <h2>Your Payment Amount</h2>
        <TextField
          {...methods.register('paymentAmount', {
            required: true,
            min: 0.01,
          })}
          error={methods.formState.errors['paymentAmount'] ? true : false}
          className='text-input'
          value={paymentAmount}
          onChange={(e) => handleCurrencyPipe(e.target.value)}
          onClick={moveInputPositionToEnd}
          onKeyDown={moveInputPositionToEnd}
          onKeyUp={moveInputPositionToEnd}
          id='paymentAmount'
          inputRef={inputRef}
          InputProps={{
            startAdornment: (
              <InputAdornment sx={{ marginRight: '-5px' }} position='start'>
                $
              </InputAdornment>
            ),
          }}
        ></TextField>
        {surchargeResponse && (
          <div className='surcharge-totals'>
            <div>
              <span>Surcharge:</span>
              <span data-cy='surcharge-amount'>{formatTransactionCurrency(surchargeResponse.surchargeAmount)}</span>
            </div>
            <div>
              <span>Total:</span>
              <span data-cy='transaction-total'>{formatTransactionCurrency(surchargeResponse.amount)}</span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SimplePaymentAmount;
