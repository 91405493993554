import lightLogo from '../../assets/images/iqpro-logo.png';
import darkLogo from '../../assets/images/dark-mode/iqpro-logo.png';
import { useTheme } from '@mui/material';
import { useContext } from 'react';
import { LayoutContext } from '../../contexts/LayoutContext';
const Header = () => {
  const theme = useTheme();
  const context = useContext(LayoutContext);
  return (
    <header>
      <div className={'heading' + (context?.layout?.isHeaderCentered ? ' centered-header' : '')}>
        {context?.layout?.header !== undefined && context?.layout?.header.length > 0 ? (
          <img src={context?.layout?.header}></img>
        ) : (
          <img src={theme.palette.mode === 'light' ? lightLogo : darkLogo}></img>
        )}
      </div>
    </header>
  );
};

export default Header;
