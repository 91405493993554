import { regexPatterns } from '../constants/regexPatterns';
import Dinero from 'dinero.js';
export const formatDateTime = (dateString: string) => {
  const date = new Date(Date.parse(dateString));

  return date.toLocaleString().replace(',', '');
};

export const formatDate = (date: string) => {
  return date.substring(0, date.length - 1) + '.000';
};

export const removeNonNumeric = (str: string) => str.replace(regexPatterns.nonNumeric, '');

export const convertToInt = (amount: number | string) => {
  if (typeof amount === 'number' && Number.isInteger(amount)) return amount;
  const converted = parseInt(removeNonNumeric(String(amount)));
  return isNaN(converted) ? 0 : converted;
};

export const formatNumber = (amount: number, precision = 2, format = '0,0.00') =>
  Dinero({ amount, precision: precision }).setLocale('en-US').toFormat(format);

export const formatTransactionCurrency = (amountValue: number | undefined | null) => {
  if (amountValue) {
    const parts = amountValue.toFixed(2).split('.');
    parts[0] = parts[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return '$' + parts.join('.');
  } else {
    return '$0.00';
  }
};
