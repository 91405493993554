import axios, { AxiosResponse } from 'axios';
import { createRef, useState, useContext } from 'react';
import { useNavigate } from 'react-router';
import { invoiceEndpoints } from '../../../core/constants/endpoints';
import CustomerPayablesPaymentInformation from './CustomerPayablesPaymentInformation';
import { IHTTPInterface } from '../../../core/interfaces/index';
import { useForm, FormProvider } from 'react-hook-form';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import ReCAPTCHA from 'react-google-recaptcha';
import { useTheme } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';
import { invoiceRoutes } from '../../../core/constants/routes';
import { CustomerPayablesContext, CustomerPayablesContextType } from '../../../contexts/CustomerPayablesContext';
import { IInvoiceResponse } from '../../../core/interfaces/index';
import { stringFormat } from '../../Global/Tokenizer/tokenizerHelpers';

export interface ITokenInterface {
  token: string | undefined;
  expirationDate?: string | undefined;
  maskedNumber?: string;
  paymentType: string;
}

export type ICustomerPayable = {
  invoiceObj: IInvoiceResponse;
};

type CustomerPayablesPaymentProps = {
  payableToken: string | undefined;
  customerPayable?: ICustomerPayable;
  setInvoiceSubmit: Dispatch<SetStateAction<boolean>>;
};

const CustomerPayablesPayment = ({ payableToken, customerPayable, setInvoiceSubmit }: CustomerPayablesPaymentProps) => {
  const {
    selectedRows,
    surcharge,
    savedSurcharges,
    amountToPay,
    modalAmounts,
    modalRows,
    modalSurcharges,
    tokenConfiguration,
    paymentTab,
    handleSwitchPayment,
    setDisableSubmit,
    setSubmitClicked,
  } = useContext(CustomerPayablesContext) as CustomerPayablesContextType;
  const navigate = useNavigate();
  const methods = useForm();
  const theme = useTheme();
  const recaptcha = createRef<ReCAPTCHA>();
  const invoiceDetails = customerPayable?.invoiceObj;
  const [openBackdrop, setOpenBackdrop] = useState<boolean>(false);
  const [errorBanner, setErrorBanner] = useState<string>('');
  const [maskedPayment, setMaskedPayment] = useState('');

  const handlePayment = (tokenPayload: ITokenInterface, modal: boolean) => {
    if (methods.formState.isValid) {
      recaptcha.current?.executeAsync().then(() => {
        setErrorBanner('');
        const addresses = formatCustomerPayableAddress();
        const payload = {
          transactionPaymentMethod: {
            ...(tokenPayload.paymentType === 'card' && {
              card: {
                cardToken: tokenPayload.token,
                maskedNumber: tokenPayload.maskedNumber,
                expirationDate: tokenPayload?.expirationDate || null,
              },
            }),
            ...(tokenPayload.paymentType === 'ach' && {
              ach: {
                achToken: tokenPayload.token,
                secCode: 2,
              },
            }),
          },

          invoiceTransactionRemits: modal
            ? modalRows.map((row) => {
                return {
                  invoiceToken: row.invoiceToken,
                  baseAmount: Number(modalAmounts[row.invoiceId![1]].replaceAll(',', '')),
                  currencyCode: 'USD',
                };
              })
            : selectedRows.map((row) => {
                return {
                  invoiceToken: row.invoiceToken,
                  baseAmount: Number(amountToPay[row.invoiceId![1]].replaceAll(',', '')),
                  currencyCode: 'USD',
                };
              }),
          transactionAddresses: addresses,
          emailReceipt: true,
        };
        createTransaction(payload, modal);
      });
    } else {
      setErrorBanner('Please fill all required fields.');
      setSubmitClicked(false);
      setDisableSubmit(false);
    }
  };

  const formatCustomerPayableAddress = () => {
    const addresses = [];
    const billing = methods.getValues().billing;
    billing.isBilling = true;
    billing.country = 'US';
    addresses.push(billing);

    if (methods.getValues()?.shipping) {
      const shipping = methods.getValues().shipping;
      shipping.isShipping = true;
      shipping.country = 'US';
      addresses.push(shipping);
    }
    return addresses;
  };

  const createTransaction = (payload: object, modal: boolean) => {
    setOpenBackdrop(true);
    const url: string = stringFormat(invoiceEndpoints.customer.transaction, [payableToken!]);
    axios
      .post(url, payload, {
        headers: {
          Authorization: payableToken,
        },
      })
      .then((response: AxiosResponse<IHTTPInterface>) => {
        const summaryUrl = invoiceRoutes.customerPaymentSummary.replace('/:payableToken', `/${payableToken}`);
        navigate(summaryUrl, {
          state: {
            responseObj: response?.data?.data,
            amountToPay: modal ? modalAmounts : amountToPay,
            maskedPayment: maskedPayment,
            ...(surcharge && paymentTab === 'card' && { savedSurcharges: modal ? modalSurcharges : savedSurcharges }),
          },
        });
      })
      .catch(() => {
        setErrorBanner(
          'An error has prevented your payment from processing. Please check that your information is correct and try again.'
        );
        setSubmitClicked(false);
        setDisableSubmit(false);
      })
      .finally(() => {
        setOpenBackdrop(false);
        setSubmitClicked(false);
        setDisableSubmit(false);
      });
  };

  return (
    <FormProvider {...methods}>
      <form>
        <div>
          <Backdrop sx={{ color: '#fff', zIndex: 9000, opacity: 0.5 }} open={openBackdrop}>
            <CircularProgress color='inherit' />
          </Backdrop>
          {invoiceDetails !== null && tokenConfiguration !== null && (
            <>
              <div className={!customerPayable ? 'payment-body' : ''}>
                <CustomerPayablesPaymentInformation
                  errorMessage={errorBanner}
                  setErrorMessage={setErrorBanner}
                  invoiceToken={customerPayable?.invoiceObj.invoiceToken}
                  handlePayment={handlePayment}
                  invoiceDetail={invoiceDetails}
                  setInvoiceSubmit={setInvoiceSubmit}
                  handleSwitchPayment={handleSwitchPayment}
                  setMaskedPayment={setMaskedPayment}
                />
              </div>
            </>
          )}
        </div>
      </form>
      <ReCAPTCHA
        ref={recaptcha}
        theme={theme.palette.mode}
        size='invisible'
        sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY || ''}
      />
    </FormProvider>
  );
};

export default CustomerPayablesPayment;
