import { useFormContext } from 'react-hook-form';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import { useState } from 'react';
import SimpleTextField from './SimplePayTextFields';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';
import { validationRules } from '../../core/constants/validationRules';
import { validateField } from '../../core/helpers/formHelpers';
import { ISimpleFieldSettings } from '../../core/interfaces/simplepay.interface';

type SimplePaymentAdditionalInfoProps = {
  fieldSettings: ISimpleFieldSettings;
};

const SimplePaymentAdditionalInfo = ({ fieldSettings }: SimplePaymentAdditionalInfoProps) => {
  const methods = useFormContext();
  const [isMultiline, setIsMultiline] = useState<boolean>(false);

  const handleExpandNote = () => {
    setIsMultiline(!isMultiline);
  };

  return (
    <div>
      <div className='two-column-row'>
        {fieldSettings.includeOrderIdField && (
          <SimpleTextField
            registration={methods.register('orderID', {
              required: fieldSettings.requireOrderIdField,
              maxLength: validationRules.maxLength50,
            })}
            error={validateField(methods.formState.errors, 'orderID')}
            label={fieldSettings.orderIdFieldLabel || 'Order ID'}
            id={'orderID'}
            asterisk={fieldSettings.requireOrderIdField}
          />
        )}
        {fieldSettings.includePoNumberField && (
          <SimpleTextField
            registration={methods.register('PONumber', {
              required: fieldSettings.requirePoNumberField,
              maxLength: validationRules.maxLength50,
            })}
            error={validateField(methods.formState.errors, 'PONumber')}
            label={fieldSettings.poNumberFieldLabel || 'PO Number'}
            id={'PONumber'}
            asterisk={fieldSettings.requirePoNumberField}
          />
        )}
      </div>
      {fieldSettings.includeNoteField && (
        <div className='field-wrapper'>
          <label>Note</label>
          <TextField
            {...methods.register('Note')}
            error={validateField(methods.formState.errors, 'Note')}
            id={'note'}
            multiline={isMultiline}
            minRows={4}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton aria-label='toggle expanded' onClick={handleExpandNote} edge='end'>
                    {isMultiline ? <UnfoldLessIcon /> : <UnfoldMoreIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          ></TextField>
        </div>
      )}
    </div>
  );
};

export default SimplePaymentAdditionalInfo;
