import axios, { AxiosResponse } from 'axios';
import { simplePayEndpoints, invoiceEndpoints } from '../../core/constants/endpoints';
import { IHTTPInterface, ISurchargeResponse, ITokenizerConfig } from '../../core/interfaces/index';
import { stringFormat } from '../Global/Tokenizer/tokenizerHelpers';
import { createRef, useContext, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { Backdrop, CircularProgress, useMediaQuery, useTheme } from '@mui/material';
import ReCAPTCHA from 'react-google-recaptcha';
import SimplePaymentInformation from './SimplePaymentInformation';
import SimplePaymentAddress from './SimplePaymentAddress';
import SimplePaymentAmount from './SimplePaymentAmount';
import SimplePaymentAdditionalInfo from './SimplePaymentAdditionalInfo';
import SimplePaymentHeader from './SimplePaymentHeader';
import {
  ISimpleFieldSettings,
  ISimplePayConfiguration,
  ISimplePayResponse,
} from '../../core/interfaces/simplepay.interface';
import SimplePaymentError from './SimplePaymentError';
import SimplePaymentSuccess from './SimplePaymentSuccess';
import { ILayout, LayoutContext } from '../../contexts/LayoutContext';
import ColorContrastChecker from 'color-contrast-checker';

export interface ITokenInterface {
  token: string | undefined;
  expirationDate?: string | undefined;
  maskedNumber?: string;
  paymentType: string;
}

function stringToBoolean(str: string): boolean {
  return str.toLowerCase() === 'true';
}

const SimplePay = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const [tokenConfiguration, setTokenConfiguration] = useState<ITokenizerConfig | null>(null);
  const [simpleConfiguration, setSimpleConfiguration] = useState<ISimplePayConfiguration | null>(null);
  const [simpleResponse, setSimpleResponse] = useState<ISimplePayResponse | null>(null);
  const [errorToken, setErrorToken] = useState<number | null>(null);
  const methods = useForm();
  const theme = useTheme();
  const layoutContext = useContext(LayoutContext);
  const recaptcha = createRef<ReCAPTCHA>();
  const [isPaid, setIsPaid] = useState<boolean>(false);
  const [openBackdrop, setOpenBackdrop] = useState<boolean>(false);
  const [errorBanner, setErrorBanner] = useState<string>('');
  const [disabledButton, setDisabledButton] = useState<boolean>(false);
  const [paymentMethodAccepted, setPaymentMethodAccepted] = useState<boolean>(true);
  const [errorCount, setErrorCount] = useState<number>(0);
  const { isValid } = methods.formState;
  const [bin, setBin] = useState<string>('');
  const [surchargeState, setSurchargeState] = useState<string>('');
  const [activeTab, setActiveTab] = useState(1);
  const [fieldSettings, setFieldSettings] = useState<ISimpleFieldSettings | null>(null);
  const [surchargeResponse, setSurchargeResponse] = useState<ISurchargeResponse | null>(null);
  const [transactionId, setTransactionId] = useState<string>('');
  const prefersDarkTheme = useMediaQuery('(prefers-color-scheme: dark)');
  const [isReceiptSent, setIsReceiptSent] = useState<boolean>(false);

  const retrieveTokenizerConfiguration = () => {
    const url: string = stringFormat(invoiceEndpoints.tokenizationConfiguration, [token!]);
    axios
      .get(url, { headers: { Authorization: token } })
      .then((tokenResponse: AxiosResponse<IHTTPInterface>) => {
        setTokenConfiguration(tokenResponse?.data?.data);
      })
      .catch((error) => {
        setErrorToken(error.response.status);
      });
  };

  const getMetaValueStr = (res: ISimplePayResponse, name: string, prefName: string) => {
    const setting = res.simplePaySettings.find((c) => {
      return c.metadataName === name && c.preferenceName === prefName;
    });
    if (!setting?.metadataValue) return '';
    return setting.metadataValue;
  };

  const getMetaValueBool = (res: ISimplePayResponse, name: string, prefName: string) => {
    const setting = res.simplePaySettings.find((c) => {
      return c.metadataName === name && c.preferenceName === prefName;
    });
    if (!setting?.metadataValue) return false;
    return stringToBoolean(setting.metadataValue);
  };

  const getMetaValueNum = (res: ISimplePayResponse, name: string, prefName: string) => {
    const setting = res.simplePaySettings.find((c) => {
      return c.metadataName === name && c.preferenceName === prefName;
    });
    if (!setting?.metadataValue) return 0;
    return parseInt(setting.metadataValue);
  };

  const retrieveSimplePayConfiguration = () => {
    const url: string = stringFormat(simplePayEndpoints.simplePayConfiguration, [token!]);
    axios
      .get(url, { headers: { Authorization: token } })
      .then((simpleResponse: AxiosResponse<IHTTPInterface>) => {
        const simplePayResponse: ISimplePayResponse = simpleResponse.data.data;
        const simpleConfig: ISimplePayConfiguration = {
          brandingSettings: {
            displayBasysBranding: getMetaValueBool(simplePayResponse, 'DISPLAY_BASYS_BRANDING', 'SimplePay Branding'), //todo implement post mvp
            displayBanner: getMetaValueBool(simplePayResponse, 'DISPLAY_BANNER', 'SimplePay Branding'),
            bannerTypeId: getMetaValueNum(simplePayResponse, 'BANNER_TYPE_ID', 'SimplePay Branding'),
            bannerMessage: getMetaValueStr(simplePayResponse, 'BANNER_MESSAGE', 'SimplePay Branding'),
            logo: getMetaValueStr(simplePayResponse, 'LOGO', 'SimplePay Branding'),
            logoAlign: getMetaValueStr(simplePayResponse, 'LOGO_ALIGN', 'SimplePay Branding'),
            primaryColor: getMetaValueStr(simplePayResponse, 'PRIMARY_COLOR', 'SimplePay Branding'),
            backgroundColor: getMetaValueStr(simplePayResponse, 'BACKGROUND_COLOR', 'SimplePay Branding'),
            paymentColor: getMetaValueStr(simplePayResponse, 'PAYMENT_COLOR', 'SimplePay Branding'),
          },
          cardSettings: {
            enableCard: getMetaValueBool(simplePayResponse, 'ENABLE_CARD', 'SimplePay Card Form Settings'),
            acceptVisa: getMetaValueBool(simplePayResponse, 'ACCEPT_VISA', 'SimplePay Card Form Settings'),
            acceptMastercard: getMetaValueBool(simplePayResponse, 'ACCEPT_MASTERCARD', 'SimplePay Card Form Settings'),
            acceptDiscover: getMetaValueBool(simplePayResponse, 'ACCEPT_DISCOVER', 'SimplePay Card Form Settings'),
            acceptAmex: getMetaValueBool(simplePayResponse, 'ACCEPT_AMEX', 'SimplePay Card Form Settings'),
            includeCompanyField: getMetaValueBool(
              simplePayResponse,
              'INCLUDE_COMPANY_FIELD',
              'SimplePay Card Form Settings'
            ),
            requireCompanyField: getMetaValueBool(
              simplePayResponse,
              'REQUIRE_COMPANY_FIELD',
              'SimplePay Card Form Settings'
            ),
            companyFieldLabel: getMetaValueStr(
              simplePayResponse,
              'COMPANY_FIELD_LABEL',
              'SimplePay Card Form Settings'
            ),
            includeOrderIdField: getMetaValueBool(
              simplePayResponse,
              'INCLUDE_ORDER_ID_FIELD',
              'SimplePay Card Form Settings'
            ),
            requireOrderIdField: getMetaValueBool(
              simplePayResponse,
              'REQUIRE_ORDER_ID_FIELD',
              'SimplePay Card Form Settings'
            ),
            orderIdFieldLabel: getMetaValueStr(
              simplePayResponse,
              'ORDER_ID_FIELD_LABEL',
              'SimplePay Card Form Settings'
            ),
            includePoNumberField: getMetaValueBool(
              simplePayResponse,
              'INCLUDE_PO_NUMBER_FIELD',
              'SimplePay Card Form Settings'
            ),
            requirePoNumberField: getMetaValueBool(
              simplePayResponse,
              'REQUIRE_PO_NUMBER_FIELD',
              'SimplePay Card Form Settings'
            ),
            poNumberFieldLabel: getMetaValueStr(
              simplePayResponse,
              'PO_NUMBER_FIELD_LABEL',
              'SimplePay Card Form Settings'
            ),
            includeAddressFields: getMetaValueBool(
              simplePayResponse,
              'INCLUDE_ADDRESS_FIELDS',
              'SimplePay Card Form Settings'
            ),
            requireAddressFields: getMetaValueBool(
              simplePayResponse,
              'REQUIRE_ADDRESS_FIELDS',
              'SimplePay Card Form Settings'
            ),
            includeEmailField: getMetaValueBool(
              simplePayResponse,
              'INCLUDE_EMAIL_FIELD',
              'SimplePay Card Form Settings'
            ),
            requireEmailField: getMetaValueBool(
              simplePayResponse,
              'REQUIRE_EMAIL_FIELD',
              'SimplePay Card Form Settings'
            ),
            includePhoneField: getMetaValueBool(
              simplePayResponse,
              'INCLUDE_PHONE_FIELD',
              'SimplePay Card Form Settings'
            ),
            requirePhoneField: getMetaValueBool(
              simplePayResponse,
              'REQUIRE_PHONE_FIELD',
              'SimplePay Card Form Settings'
            ),
            includeNoteField: getMetaValueBool(simplePayResponse, 'INCLUDE_NOTE_FIELD', 'SimplePay Card Form Settings'),
            additionalInfoHeader: getMetaValueStr(
              simplePayResponse,
              'ADDITIONAL_INFO_HEADER',
              'SimplePay Card Form Settings'
            ),
          },
          achSettings: {
            enableAch: getMetaValueBool(simplePayResponse, 'ENABLE_ACH', 'SimplePay ACH Form Settings'),
            includeCompanyField: getMetaValueBool(
              simplePayResponse,
              'INCLUDE_COMPANY_FIELD',
              'SimplePay ACH Form Settings'
            ),
            requireCompanyField: getMetaValueBool(
              simplePayResponse,
              'REQUIRE_COMPANY_FIELD',
              'SimplePay ACH Form Settings'
            ),
            companyFieldLabel: getMetaValueStr(simplePayResponse, 'COMPANY_FIELD_LABEL', 'SimplePay ACH Form Settings'),
            includeOrderIdField: getMetaValueBool(
              simplePayResponse,
              'INCLUDE_ORDER_ID_FIELD',
              'SimplePay ACH Form Settings'
            ),
            requireOrderIdField: getMetaValueBool(
              simplePayResponse,
              'REQUIRE_ORDER_ID_FIELD',
              'SimplePay ACH Form Settings'
            ),
            orderIdFieldLabel: getMetaValueStr(
              simplePayResponse,
              'ORDER_ID_FIELD_LABEL',
              'SimplePay ACH Form Settings'
            ),
            includePoNumberField: getMetaValueBool(
              simplePayResponse,
              'INCLUDE_PO_NUMBER_FIELD',
              'SimplePay ACH Form Settings'
            ),
            requirePoNumberField: getMetaValueBool(
              simplePayResponse,
              'REQUIRE_PO_NUMBER_FIELD',
              'SimplePay ACH Form Settings'
            ),
            poNumberFieldLabel: getMetaValueStr(
              simplePayResponse,
              'PO_NUMBER_FIELD_LABEL',
              'SimplePay ACH Form Settings'
            ),
            includeNoteField: getMetaValueBool(simplePayResponse, 'INCLUDE_NOTE_FIELD', 'SimplePay ACH Form Settings'),
            additionalInfoHeader: getMetaValueStr(
              simplePayResponse,
              'ADDITIONAL_INFO_HEADER',
              'SimplePay ACH Form Settings'
            ),
          },
        };
        setSimpleResponse(simplePayResponse);
        setSimpleConfiguration(simpleConfig);
      })
      .catch((error) => {
        setErrorToken(error.response.status);
      });
  };

  const handlePayment = (tokenPayload: ITokenInterface) => {
    if (isValid && (paymentMethodAccepted || tokenPayload.paymentType === 'ach')) {
      recaptcha.current?.executeAsync().then(() => {
        setErrorBanner('');
        const addresses = formatAddress();
        const payload = {
          transactionPaymentMethod: {
            ...(tokenPayload.paymentType === 'card' && {
              card: {
                cardToken: tokenPayload.token,
                maskedNumber: tokenPayload.maskedNumber,
                expirationDate: tokenPayload?.expirationDate || null,
              },
            }),
            ...(tokenPayload.paymentType === 'ach' && {
              ach: {
                achToken: tokenPayload.token,
                secCode: null,
              },
            }),
          },
          transactionRemit: {
            baseAmount: methods.getValues('paymentAmount'),
            currencyCode: 'USD',
          },
          transactionAddress: addresses,
          emailReceipt: false,
          emailAddress: methods.getValues('billing.email'),
          customFields: [],
          description: methods.getValues('Note') || null,
          orderId: methods.getValues('orderID') || null,
          poNumber: methods.getValues('PONumber') || null,
        };
        if (methods.getValues('receipt')) {
          payload.emailReceipt = methods.getValues('receipt');
        }
        createTransaction(payload);
      });
    } else if (!paymentMethodAccepted && tokenPayload.paymentType === 'card') {
      setErrorBanner('Card type not supported');
      setErrorCount(errorCount + 1);
    } else {
      setErrorBanner('Please fill all required fields.');
      setErrorCount(errorCount + 1);
    }
  };

  const createTransaction = (payload: object) => {
    setOpenBackdrop(true);
    const url: string = token + '/transaction';
    axios
      .post(url, payload, { headers: { Authorization: token } })
      .then((response: AxiosResponse<IHTTPInterface>) => {
        if (response?.data?.data?.responseCode !== 400 && response?.data?.data?.response.toLowerCase() !== 'declined') {
          setTransactionId(response?.data?.data?.transactionId);
          setIsPaid(true);
          if (response?.data?.data?.responseCode !== 204 && methods.getValues('receipt')) {
            setIsReceiptSent(true);
          } else {
            setIsReceiptSent(false);
          }
        } else {
          setErrorBanner('Transaction Declined: ' + response?.data?.data?.processorResponseText);
          setErrorCount(errorCount + 1);
        }
      })
      .catch(() => {
        setErrorBanner(
          'An error has prevented your payment from processing. Please check that your information is correct and try again.'
        );
        setErrorCount(errorCount + 1);
      })
      .finally(() => {
        setOpenBackdrop(false);
      });
  };

  //TODO: in invoicing, this works by forcing address to be US. Does this make sense in this case?
  const formatAddress = () => {
    const addresses = [];
    const billing = methods.getValues('billing');
    billing.isBilling = true;
    billing.country = methods.getValues('billing.country') || 'US';
    addresses.push(billing);
    return addresses;
  };

  const handleStateChange = (state: string) => {
    setSurchargeState(state);
  };

  const calculatePaymentType = (hasCardForm: boolean, hasAchForm: boolean) => {
    if (hasCardForm) {
      return hasAchForm ? 'BOTH' : 'CARD';
    } else {
      return 'ACH';
    }
  };

  useEffect(() => {
    retrieveTokenizerConfiguration();
    retrieveSimplePayConfiguration();
  }, []);

  useEffect(() => {
    if (activeTab === 2) {
      setSurchargeResponse(null);
    }
  }, [activeTab]);

  useEffect(() => {
    if (simpleConfiguration) {
      if (activeTab === 1) {
        setFieldSettings(simpleConfiguration.cardSettings);
      } else {
        setFieldSettings({
          ...simpleConfiguration.achSettings,
          includeAddressFields: true,
          requireAddressFields: true,
          includePhoneField: true,
          requirePhoneField: true,
          includeEmailField: true,
          requireEmailField: true,
        });
      }
    }
  }, [activeTab, simpleConfiguration]);

  useEffect(() => {
    const paymentType = calculatePaymentType(
      simpleConfiguration?.cardSettings.enableCard || false,
      simpleConfiguration?.achSettings.enableAch || false
    );
    const ccc = new ColorContrastChecker();
    setActiveTab(paymentType === 'BOTH' || paymentType === 'CARD' ? 1 : 2);
    const root = document.documentElement;
    if (prefersDarkTheme) {
      root?.style.setProperty('--background-color', '#121212');
      root?.style.setProperty('--branding-typography-bg', '#FFF');
      root?.style.setProperty('--branding-typography-payment', '#FFF');
    } else {
      root?.style.setProperty('--background-color', simpleConfiguration?.brandingSettings.backgroundColor || '');
      root?.style.setProperty(
        '--branding-typography-bg',
        ccc.isLevelAA(simpleConfiguration?.brandingSettings.backgroundColor || '', '#FFF') ? '#FFF' : '#000'
      );
      root?.style.setProperty(
        '--branding-typography-payment',
        ccc.isLevelAA(simpleConfiguration?.brandingSettings.paymentColor || '', '#FFF') ? '#FFF' : '#000'
      );
      if (
        simpleConfiguration?.brandingSettings.backgroundColor !== '#FFF' &&
        simpleConfiguration?.brandingSettings.backgroundColor
      ) {
        root?.style.setProperty('--background-image', 'none');
      }
    }

    const layout: ILayout = {
      header: simpleConfiguration?.brandingSettings.logo || '',
      footer: '',
      isHeaderCentered: simpleConfiguration?.brandingSettings.logoAlign === 'center',
      showBackground: true,
    };
    layoutContext?.setLayout(layout);
  }, [simpleConfiguration, prefersDarkTheme]);

  return (
    <div className='simplepay'>
      <FormProvider {...methods}>
        <form>
          <div>
            <Backdrop sx={{ color: '#fff', zIndex: 9000, opacity: 0.5 }} open={openBackdrop}>
              <CircularProgress color='inherit' />
            </Backdrop>
            {tokenConfiguration !== null &&
            simpleConfiguration !== null &&
            simpleResponse !== null &&
            fieldSettings !== null ? (
              <>
                {isPaid ? (
                  <SimplePaymentSuccess
                    transactionId={transactionId}
                    simpleConfiguration={simpleConfiguration}
                    surchargeResponse={surchargeResponse}
                    isReceiptSent={isReceiptSent}
                    prefersDarkTheme={prefersDarkTheme}
                  />
                ) : (
                  <>
                    <SimplePaymentHeader simplePayName={simpleResponse.name!} />
                    {/* TODO This is an optional field... */}
                    <div className='main-row'>
                      <div className='left-column'>
                        <div style={{ marginBottom: '48px' }}>
                          <SimplePaymentAmount
                            simpleConfiguration={simpleConfiguration!}
                            bin={bin}
                            simpleToken={token!}
                            surchargeState={surchargeState}
                            surchargeResponse={surchargeResponse}
                            setSurchargeResponse={setSurchargeResponse}
                            setPaymentMethodAccepted={setPaymentMethodAccepted}
                            setDisabledButton={setDisabledButton}
                            activeTab={activeTab}
                          />
                        </div>
                        <SimplePaymentInformation
                          tokenConfiguration={tokenConfiguration!}
                          simpleConfiguration={simpleConfiguration!}
                          fieldSettings={fieldSettings!}
                          simpleToken={token!}
                          handlePayment={handlePayment}
                          paymentType={calculatePaymentType(
                            simpleConfiguration.cardSettings?.enableCard,
                            simpleConfiguration.achSettings?.enableAch
                          )}
                          errorCount={errorCount}
                          errorMessage={errorBanner}
                          simplePayName={simpleResponse.name!}
                          setBin={setBin}
                          activeTab={activeTab}
                          setActiveTab={setActiveTab}
                          disabledButton={disabledButton}
                          setDisabledButton={setDisabledButton}
                          surchargeResponse={surchargeResponse}
                        />
                      </div>
                      <div className='right-column'>
                        {simpleConfiguration.brandingSettings?.displayBanner &&
                          simpleConfiguration.brandingSettings.bannerMessage && (
                            <div
                              className={
                                'message-banner' +
                                (simpleConfiguration.brandingSettings?.bannerTypeId === 1
                                  ? ' banner-type-warning'
                                  : '') +
                                (simpleConfiguration.brandingSettings?.bannerTypeId === 2
                                  ? ' banner-type-regular'
                                  : '') +
                                (simpleConfiguration.brandingSettings?.bannerTypeId === 3
                                  ? ' banner-type-success'
                                  : '') +
                                (simpleConfiguration.brandingSettings?.bannerTypeId === 4
                                  ? ' banner-type-dangerous'
                                  : '')
                              }
                            >
                              {simpleConfiguration.brandingSettings?.bannerMessage}
                            </div>
                          )}
                        <div className='address-container'>
                          <h3>Billing Information</h3>
                          <SimplePaymentAddress
                            type={'billing'}
                            fieldSettings={fieldSettings!}
                            handleStateChange={handleStateChange}
                          />
                        </div>
                        <div className='additional-info-container'>
                          <h3>
                            {(fieldSettings?.includeOrderIdField ||
                              fieldSettings?.includePoNumberField ||
                              fieldSettings?.includeNoteField) &&
                              (fieldSettings?.additionalInfoHeader || 'Additional Information')}
                          </h3>
                          <SimplePaymentAdditionalInfo fieldSettings={fieldSettings!} />
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </>
            ) : (
              errorToken && <SimplePaymentError errorCode={errorToken} />
            )}
          </div>
        </form>
        <ReCAPTCHA
          ref={recaptcha}
          theme={theme.palette.mode}
          size='invisible'
          sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY || ''}
        />
      </FormProvider>
    </div>
  );
};
export default SimplePay;
