import invoiceExample from '../../assets/images/landing/invoice-example.png';
import invoiceStatus from '../../assets/images/landing/invoice-statuses.png';
import customerImage from '../../assets/images/customer-search.png';

const InvoiceLanding = () => {
  return (
    <div>
      <div className='landing-container'>
        <div className='heading-block'>
          <h1 className='underline' data-testid='landing-header'>
            Effortlessly manage & send invoices from the iQ Pro+ platform.
          </h1>
        </div>
        <div className='invoice-example-block'>
          <img src={invoiceExample}></img>
        </div>
        <div className='detail-block'>
          <p>iQ Pro's Invoicing Feature is designed to get you paid quicker without the hassle.</p>
          <ul>
            <li>Create, manage, and send invoices from your desk or on the go</li>
            <li>Track and display prior payments, tax calculation, shipping amounts, discounts, and more</li>
            <li>Customization options tailored to your unique brand</li>
          </ul>
          <a className='buttonStyle primary learn-more-desktop' href={'https://iqpro.com'}>
            Learn More
          </a>
        </div>
        <div className='two-picture-block'>
          <img src={customerImage}></img>
          <img src={invoiceStatus}></img>
        </div>
        <div className='learn-more-mobile'>
          <a className='buttonStyle primary' href={'https://iqpro.com'}>
            Learn More
          </a>
        </div>
      </div>
    </div>
  );
};

export default InvoiceLanding;
