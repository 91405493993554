import { TableRow, TableCell } from '@mui/material';
import { formatTransactionCurrency } from '../../../core/helpers/stringHelpers';
import { CustomerPayableInterface } from '../CustomerPayables/CustomerPayables';
import { CustomerPayablesContext, CustomerPayablesContextType } from '../../../contexts/CustomerPayablesContext';
import { useContext } from 'react';

type InvoiceListTotalsRowProps = {
  displayedRows: CustomerPayableInterface[];
  totalAmountToPay: number;
  totalSurcharge: number;
};
const InvoiceListTotalsRow = ({ displayedRows, totalAmountToPay, totalSurcharge }: InvoiceListTotalsRowProps) => {
  const { surcharge, paymentTab } = useContext(CustomerPayablesContext) as CustomerPayablesContextType;
  return (
    <TableRow className='invoice-total-amounts'>
      {displayedRows.some((r) => {
        return r.invoiceDate;
      }) && <TableCell></TableCell>}

      <TableCell></TableCell>
      <TableCell></TableCell>
      <TableCell></TableCell>
      <TableCell>
        {formatTransactionCurrency(
          displayedRows.reduce((a: number, r: CustomerPayableInterface) => a + r.originalBalance!, 0)
        )}
      </TableCell>
      <TableCell>
        {formatTransactionCurrency(
          displayedRows.reduce((a: number, r: CustomerPayableInterface) => a + r.remainingBalance!, 0)
        )}
      </TableCell>
      <TableCell> {formatTransactionCurrency(totalAmountToPay)}</TableCell>
      {surcharge && paymentTab === 'card' && (
        <>
          <TableCell>{formatTransactionCurrency(totalSurcharge)}</TableCell>
          <TableCell className='surcharge-total'>
            {formatTransactionCurrency(totalAmountToPay + totalSurcharge)}
          </TableCell>
        </>
      )}
    </TableRow>
  );
};

export default InvoiceListTotalsRow;
