import { TableCell, TextField, Tooltip, Grid } from '@mui/material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import InputAdornment from '@mui/material/InputAdornment';
import { formatNumber, convertToInt, formatTransactionCurrency } from '../../../core/helpers/stringHelpers';
import { CustomerPayableInterface } from '../CustomerPayables/CustomerPayables';
import { Dispatch, SetStateAction, useContext, useState, useEffect, useRef } from 'react';
import { CustomerPayablesContextType, CustomerPayablesContext } from '../../../contexts/CustomerPayablesContext';
import { ChangeEvent } from 'react';

type InvoiceTableCellsProps = {
  type: string;
  row: CustomerPayableInterface;
  surchargeList: { [key: string]: number };
  amountList: { [key: string]: string };
  setAmountList: Dispatch<SetStateAction<{ [key: string]: string }>>;
  loadingTenSurcharges?: boolean;
  getSurchargeAmount: (idArray: string[], inputValue?: number | null, recalculating?: boolean) => void;
  disableModalSubmit?: boolean;
  setDisableModalSubmit?: Dispatch<SetStateAction<boolean>>;
  setModalSurchargeLoading?: Dispatch<SetStateAction<boolean>>;
  modalErrorList?: string[];
  setModalErrorList?: Dispatch<SetStateAction<string[]>>;
  setModalSurcharges?: Dispatch<SetStateAction<{ [key: string]: number }>>;
  allowPartialPayments?: boolean;
};

const InvoiceTableCells = ({
  type,
  row,
  surchargeList,
  amountList,
  setAmountList,
  loadingTenSurcharges,
  disableModalSubmit,
  setDisableModalSubmit,
  getSurchargeAmount,
  setModalSurchargeLoading,
  modalErrorList,
  setModalErrorList,
  setModalSurcharges,
  allowPartialPayments,
}: InvoiceTableCellsProps) => {
  const statusColors: { [key: string]: string } = {
    sent: '#4CAF50',
    partiallyPaid: '#ED6C02',
    overdue: '#D32F2F',
  };

  const {
    selectedRows,
    surcharge,
    paymentTab,
    binFilled,
    disableSubmit,
    setDisableSubmit,
    loadingSurcharge,
    setLoadingSurcharge,
    errorList,
    setErrorList,
    setSavedSurcharges,
  } = useContext(CustomerPayablesContext) as CustomerPayablesContextType;
  const isItemSelected = selectedRows.map((r) => r.invoiceId![1]).includes(row.invoiceId![1]);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const inputRef: any = useRef();
  const moveInputPositionToEnd = () => {
    inputRef.current!.setSelectionRange(Number.MAX_SAFE_INTEGER, Number.MAX_SAFE_INTEGER);
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [inputAmount, setInputAmount] = useState('0');

  useEffect(() => {
    if (convertToInt(inputAmount) > 0) {
      if (type === 'modal' && setModalSurchargeLoading !== undefined) {
        setModalSurchargeLoading(true);
      } else {
        setLoadingSurcharge(true);
      }

      if (surcharge && paymentTab === 'card') {
        getSurchargeAmount([row.invoiceId![1]], Number(convertToInt(inputAmount) / 100));
      }
    }
  }, [inputAmount]);

  const handleAmountChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setAmountList({
      ...amountList,
      [row.invoiceId![1]]: formatNumber(convertToInt(e.target.value), 2, '0,0.00'),
    });

    if (
      Number(formatNumber(convertToInt(e.target.value), 2, '0,0.00').replaceAll(',', '')) > row.remainingBalance! ||
      (selectedRows.map((r) => r.invoiceId![1]).includes(row.invoiceId![1]) &&
        Number(formatNumber(convertToInt(e.target.value), 2, '0,0.00').replaceAll(',', '')) === 0)
    ) {
      if (type === 'list') {
        if (!errorList.includes(row.invoiceId![1])) {
          setErrorList([...errorList, row.invoiceId![1]]);
        }
        if (!disableSubmit) {
          setDisableSubmit(true);
        }
      } else if (type === 'modal') {
        if (modalErrorList && setModalErrorList && !modalErrorList.includes(row.invoiceId![1])) {
          setModalErrorList([...modalErrorList, row.invoiceId![1]]);
        }
        if (!disableModalSubmit) {
          setDisableModalSubmit!(true);
        }
      }
    } else {
      if (type === 'list') {
        if (errorList.includes(row.invoiceId![1])) {
          setErrorList(errorList.filter((id) => id !== row.invoiceId![1]));
        }
        if (disableSubmit) {
          setDisableSubmit(false);
        }
      } else if (type === 'modal') {
        if (modalErrorList && setModalErrorList && modalErrorList.includes(row.invoiceId![1])) {
          setModalErrorList(modalErrorList.filter((id) => id !== row.invoiceId![1]));
        }
        if (disableModalSubmit) {
          setDisableModalSubmit!(false);
        }
      }
    }
  };

  const disableAmountField = () => {
    return (
      type === 'list' &&
      (!isItemSelected || loadingTenSurcharges || loadingSurcharge || (isItemSelected && !allowPartialPayments))
    );
  };

  return (
    <>
      {Object.keys(row).map((field, i) => {
        if (field !== 'id' && field !== 'invoicePaymentMethod' && field !== 'invoiceToken' && field !== 'invoiceNumber')
          return (
            <TableCell
              data-cy={`${field + i}`}
              key={i}
              component='th'
              scope='row'
              className={`${
                type === 'list' &&
                surcharge &&
                binFilled &&
                paymentTab === 'card' &&
                field === 'surcharge' &&
                surchargeList[row.invoiceId![1]] > 0
                  ? 'blue-text'
                  : field === 'total'
                  ? surchargeList[row.invoiceId![1]] + Number(amountList[row.invoiceId![1]].replaceAll(',', '')) > 0
                    ? 'total-cell bold'
                    : 'total-cell'
                  : ''
              }`}
            >
              {field === 'remainingBalance' || field === 'originalBalance' ? (
                formatTransactionCurrency(row[field]!)
              ) : field === 'amountPay' ? (
                <Tooltip
                  title={type === 'list' && (loadingTenSurcharges || loadingSurcharge) ? 'Calculating surcharge' : ''}
                >
                  <span>
                    <TextField
                      id={`${row.id}`}
                      //disableSubmit: when calculating surcharge and when incorrect amount entered
                      disabled={disableAmountField()}
                      sx={{ m: 1, width: '150px', marginLeft: '-45px' }}
                      size='small'
                      value={amountList[row.invoiceId![1]]}
                      inputRef={inputRef}
                      onChange={(e) => {
                        handleAmountChange(e);
                      }}
                      onBlur={(e) => {
                        if (Number(e.target.value.replaceAll(',', '')) <= row.remainingBalance!) {
                          if (surcharge && paymentTab === 'card' && e.target.value !== '0.00') {
                            setInputAmount(e.target.value);
                          } else {
                            if (type === 'list') {
                              setSavedSurcharges({
                                ...surchargeList,
                                [row.invoiceId![1]]: 0,
                              });
                            } else if (setModalSurcharges) {
                              setModalSurcharges({ ...surchargeList, [row.invoiceId![1]]: 0 });
                            }
                          }
                        }
                      }}
                      onClick={moveInputPositionToEnd}
                      InputProps={{
                        startAdornment: <InputAdornment position='start'>$</InputAdornment>,
                        inputProps: {
                          style: { textAlign: 'right' },
                        },
                      }}
                      error={
                        Number(amountList[row.invoiceId![1]].replaceAll(',', '')) > row.remainingBalance! ||
                        (selectedRows.map((r) => r.invoiceId![1]).includes(row.invoiceId![1]) &&
                          Number(amountList[row.invoiceId![1]].replaceAll(',', '')) === 0)
                      }
                      helperText={
                        Number(amountList[row.invoiceId![1]].replaceAll(',', '')) > row.remainingBalance! ||
                        (selectedRows.map((r) => r.invoiceId![1]).includes(row.invoiceId![1]) &&
                          Number(amountList[row.invoiceId![1]].replaceAll(',', '')) === 0)
                          ? 'Invalid amount entered'
                          : ''
                      }
                    />
                  </span>
                </Tooltip>
              ) : field === 'invoiceId' ? (
                <Tooltip title={row?.invoiceNumber || row[field]![1]}>
                  {type === 'list' ? (
                    <Grid container alignItems={'center'} flexDirection={'row'} justifyContent={'space-between'}>
                      <Grid item xs={3}>
                        <FiberManualRecordIcon
                          fontSize='inherit'
                          htmlColor={statusColors[row[field]![0]]}
                          style={{ alignSelf: 'center' }}
                        />
                      </Grid>
                      <Grid item xs={9} className='invoice-id-cell'>
                        {row?.invoiceNumber || row[field]![1]}
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid
                      item
                      xs={9}
                      sx={{
                        textWrap: 'nowrap',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                      }}
                    >
                      {row?.invoiceNumber || row[field]![1]}
                    </Grid>
                  )}
                </Tooltip>
              ) : field === 'surcharge' ? (
                typeof surchargeList[row.invoiceId![1]] === 'number' ? (
                  formatTransactionCurrency(surchargeList[row.invoiceId![1]])
                ) : (
                  formatTransactionCurrency(0)
                )
              ) : field === 'total' ? (
                formatTransactionCurrency(
                  surchargeList[row.invoiceId![1]] + Number(amountList[row.invoiceId![1]].replaceAll(',', ''))
                )
              ) : (
                row[field as keyof typeof row]
              )}
            </TableCell>
          );
      })}
    </>
  );
};
export default InvoiceTableCells;
